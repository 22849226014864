import React, { useState, useRef, useContext, useEffect } from "react";
import { MainLogo } from "../MainLogo";
import { LeftMenu } from "../../components/LeftMenu";
import { MessagePanel } from "../MessagePanel";
import { RightMenu } from "../RightMenu";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import ForgeryApi from "../../api/forgerys";
import useForm from '../hooks/useForm';
import DeterminedApi from "../../api/determineds";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { platforms, platformOptions } from "../../config/codes";
import { categories, categoryOptions } from "../../config/codes";
import { currencyUnits, currencyUnitOptions } from "../../config/codes";

export const ForgeryDetail = ({prd}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);  

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState((prd && prd.determin_image1) ? displayImage(prd.determin_image1) : null );
  const [ imageChecked, setImageChecked ] = useState(false);
  const [error, setError] = useState("");

  // 판별 결과 JSON
  let dtms = JSON.parse(prd.determins);

  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': prd.infringe,
    'content': prd.content
  });

  const [selectedInfringeOption, setSelectedInfringeOption] = useState(prd.infringe);
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  const refThumbMain = useRef();
  const refProductImage = useRef();
  const refProductFile = useRef();
  const refDeterminImage = useRef();
  const refDeterminFile = useRef();
  const refSrcName = useRef();
  const refSrcDesc = useRef();
  const refSimilarity = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();

    // 위조품 데이터 재거 서버에 전송
    let formData = new FormData();
    formData.append("id", _id);
    ForgeryApi.DeleteForgery(_id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`위조품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    // if (determinDesc.length <= 0) {
    //   const result = alert("침해된 이미지를 선택해 주세요!");
    //   return false;
    // }
    if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(formData) {
    DeterminedApi.UpdateDetermined(prd.id, formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked() {
    let formData = new FormData();
    formData.append("infringe", selectedInfringeOption);
    formData.append("content", content);
    InsertDeterminedData(formData);
  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      postDeterminedByClicked();
    }  
  }
  
  const onImageCheck = (prd_param, e) => {
    e.preventDefault();
    let desc = '';
    let date = new Date();
    let tm = date.getTime();
    prd.product_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.official_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.determined_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    setImageChecked(!imageChecked);
    setDeterminDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }
  
  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="forgery-detail">
      <div className="div-10">
        <MainLogo className="main-logo-4" />
        <RightMenu className="right-menu-5" />
        <div className="main-frame-3">
          <div className="body-title-2">
            <div className="title-5">가품 상세</div>
          </div>

          <div className="section-frame-11">
            <div className="frame-44">
              <div className="frame-45">
                {/* <img className="div-image-2" alt="Div image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a161ac9f83776dc7b8c93/img/div-image-2-2.png" /> */}
                <div className="div-image-2" style={{backgroundImage: `url(${displayImage(thumbClickedUrl)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center' }} ref={refThumbMain} />

              </div>

              <div className="frame-46">
                {/* <div className="frame-47" />
                <div className="frame-48" />
                <div className="frame-49" />
                <div className="frame-50" />
                <div className="frame-51" /> */}
              { 
                [prd.determin_image1, prd.determin_image2, prd.determin_image3, prd.determin_image4, prd.determin_image5].map((thumburl, index, total) => (
                  thumburl && thumburl.length > 0 ?
                  <div className={`frame-${index+47}`} style={{cursor:'pointer', border:'1px solid', backgroundImage: `url(${displayImage(thumburl)})`, backgroundSize:'contain', borderColor:'#dddddd', borderWidth: 2,borderStyle: 'solid'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                  : null
                ))
              }


              </div>
            </div>

            <div className="frame-52">
              <div className="frame-53">
                <div className="text-wrapper-26">플랫폼 : {platforms[prd.mall]}</div>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">카테고리 : {categories[prd.category]}</div>
              </div>

              <div className="frame-54">
                <p className="text-wrapper-28">
                {prd.determin_name ? prd.determin_name.substring(0,100) : null}
                </p>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">정상품 판매가 : {prd.product_price.toLocaleString('ko-KR')}({prd.currency})</div>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">정상품 하한가 : {(prd.price_floor*(prd.krw_exrate === 0.0 ? 1.0 : prd.krw_exrate)).toLocaleString('ko-KR')}</div>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">위조품 판매가 : {(prd.determin_price*(prd.krw_exrate === 0.0 ? 1.0 : prd.krw_exrate)).toLocaleString('ko-KR')}</div>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">침해유형 : {infringeTypes[prd.infringe]}</div>
              </div>

              <div className="frame-53">
                <a className="text-wrapper-27" href={prd.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {prd.seller} 홈</a>
              </div>

              <div className="frame-53">
                <a className="text-wrapper-27" href={prd.url} target="blank" style={{"color": "blue"}}>위조품 상세보기</a>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">수집일시 : {prd.created_at.substring(0,19)}</div>
              </div>

              <div className="frame-53">
                <div className="text-wrapper-27">&nbsp;</div>
              </div>
            </div>
          </div>

          <div className="section-frame-12">
            <div className="frame-55">
              <div className="text-wrapper-29">타이틀유사도</div>
            </div>

            <div className="frame-56">
              <div className="frame-57">
                <div className="text-wrapper-30">정품 제목</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">{prd.product_name}</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">위조품 제목</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">{prd.determin_name.substring(0,40)}</div>
              </div>
            </div>

            <div className="frame-56">
              <div className="frame-59">
                <div className="text-wrapper-30">브랜드/모델</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">{prd.brand} / {prd.model}</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">타이틀 유사도</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">{parseFloat(prd.similarity).toFixed(2)}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-12">
            <div className="frame-61">
              <div className="text-wrapper-29">가격 유사도({prd.currency ? currencyUnits[prd.currency] : null})</div>
            </div>

            <div className="frame-56">
              <div className="frame-57">
                <div className="text-wrapper-30">정품 기준가</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">{prd.product_price ? prd.product_price.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">위조품 가격</div>
              </div>

              <div className="frame-58">
                <div className="text-wrapper-30">{prd.determin_price ? prd.determin_price.toLocaleString('ko-KR') : null}</div>
              </div>
            </div>

            <div className="frame-56">
              <div className="frame-59">
                <div className="text-wrapper-30">정품 하한가</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">{prd.price_floor ? prd.price_floor.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">정품 하한가 기준</div>
              </div>

              <div className="frame-60">
                <div className="text-wrapper-30">{(prd.determin_price - prd.price_floor).toLocaleString('ko-KR')}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-13">
            <div className="frame-53">
              <div className="text-wrapper-29">이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-14">

          { dtms.map((dtm, index, total) => ( 

            <div className="rec-item-frame-3">
              <div className="div-11">
                <div className="thumbnail-5">
                  <div className="overlap-group-4">
                    <div className="back-image-frame-2">
                      {/* <img className="back-image-3" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      <div className="back-image-3" style={{backgroundImage: `url(${displayImage(dtm.product_image)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-5">
                      <div className="button-box-4"></div>
                    </button> */}
                  </div>
                </div>

                <div className="info-frame-3">
                  <div className="frame-62">
                    <div className="frame-59">
                      <div className="text-wrapper-30">{dtm.src_name}</div>
                    </div>

                    <div className="frame-60">
                      <div className="text-wrapper-30">{dtm.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-62">
                    <div className="frame-59">
                      <div className="text-wrapper-30">&nbsp;</div>
                    </div>

                    <div className="frame-60">
                      <div className="text-wrapper-30">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-63" />
                </div>
              </div>

              <div className="div-11">
                <div className="thumbnail-6">
                  <div className="overlap-group-4">
                    <div className="back-image-frame-2">
                      {/* <img className="back-image-3" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png"/> */}
                      <div className="back-image-3" style={{backgroundImage: `url(${displayImage(dtm.determin_image)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>

                    </div>

                    {/* <button className="button-5">
                      <div className="button-box-4"></div>
                    </button> */}
                  </div>
                </div>

                <div className="info-frame-3">
                  <div className="frame-62">
                    <div className="frame-60">
                      <div className="text-wrapper-30">similarity</div>
                    </div>

                    <div className="frame-60">
                      <div className="text-wrapper-30">{dtm.similarity ? parseFloat(dtm.similarity).toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-62">
                    <div className="frame-60">
                      <div className="text-wrapper-30">&nbsp;</div>
                    </div>

                    <div className="frame-60">
                      <div className="text-wrapper-30">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-63" />
                </div>
              </div>
            </div>
          ))}
            
          </div>

          <div className="section-frame-15">
            <div className="form-title-frame-2">
              <div className="form-title-3">판별하기</div>
            </div>

            <div className="form-caption-frame-3">
              <div className="frame-64">
                <div className="form-caption-3">판별 내용</div>
              </div>

              <div className="frame-65">
                <div className="text-wrapper-31">침해 사유</div>
              </div>

              <div className="frame-66">
                <div className="text-wrapper-31">판별 사유</div>
              </div>
            </div>

            <div className="form-text-frame-3">
              <div className="form-text-3">내용을 입력해 주세요</div>
            </div>

            <div className="form-submit-frame-3">
              <div className="form-submit-layout-4">
                <div className="form-submit-5">삭&nbsp;&nbsp;제</div>
              </div>

              <div className="form-submit-layout-5">
                <div className="form-submit-6">저&nbsp;&nbsp;장</div>
              </div>

              <div className="form-submit-layout-5">
                <div className="form-submit-6">신 고하기</div>
              </div>
            </div>
          </div>
        </div>

        <LeftMenu className="left-menu-5" />
      </div>
    </div>
  );
};
