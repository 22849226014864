import React from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

export const LawsuitDetail = ({law}) => {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }

  return (
    <div className="lawsuit-detail">
      <div className="div-4">
        <FooterPanel className="footer-panel-3" />
        <div className="body-frame-2">
          <div className="bulletin-body-title-2">
            <div className="title-3">IP 소송 금융 상세</div>
          </div>

          <div className="body-sub-title-2" />

          <div className="body-title-frame-2">
            <div className="text-wrapper-26">소송명</div>
          </div>

          <div className="form-wrapper">
            <div className="form-2">
              <div className="fieldset-2">
                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">플랫폼:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.platform}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">진행상황:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.progress}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">판매상명:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.seller_name}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">제품:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.product}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">공증번호:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.notarization_no}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">피고:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.defendant}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">1심법원:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.first_trial_court}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">1심사건번호:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.first_trial_case_no}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">제품판매여부:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.sold_type}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">종결여부:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.finished}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-3">
                  <div className="container-5">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">비고:</div>
                    </div>

                    <div className="data-frame-7">
                      <div className="text-wrapper-29" dangerouslySetInnerHTML={{__html: law.comment}}></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">제출일자:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.submit_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">입안일자:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.entried_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-27">공증일자:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-28">{law.notarized_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-28"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-frame-wrapper">
                <div className="list-frame-2" style={{cursor:'pointer'}} onClick={() => {navigate(-1)}}>
                  <div className="text-wrapper-30">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RightMenu className="right-menu-3" />
        <MainLogo className="main-logo-3" />
        <LeftMenu className="left-menu-3" />
      </div>
    </div>
  );
};
