import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { Link, useNavigate } from "react-router-dom";

export const MainLogo = ({ className, href }) => {
  const navigate = useNavigate();
  const { user, setUser, global, setGlobal } = useAuth();

  // 메인 홈
  const handleHome = () => {
    navigate("/");
  };
  
  return (
    <div className={`main-logo ${className}`}>
      <img className="link" alt="Link" src="/img/main_logo.png" style={{cursor:"pointer"}} onClick={handleHome} />

    </div>
  );
};
