import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams, useNavigationType } from "react-router-dom";
import { ProductListContainer } from "../../containers/ProductListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const ProductListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  

  // Filter Variables
  let brand = '';
  let searchType = '';
  let searchText = '';
  let page = '1';
  if (navType === 'PUSH') {
    localStorage.setItem('brand', brand);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
  } else {
    brand = localStorage.getItem('brand');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    page = localStorage.getItem('page');
  }
  

  return (
    <ProductListContainer 
      brandParam={brand} 
      searchTypeParam={searchType} 
      searchTextParam={searchText} 
      pageParam={parseInt(page)} 
    />
  );
}

