import * as inquestApi from "../api/inquests";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_INQUESTLIST = "GET_INQUESTLIST";
const GET_INQUESTLIST_SUCCESS = "GET_INQUESTLIST_SUCCESS";
const GET_INQUESTLIST_ERROR = "GET_INQUESTLIST_ERROR";

// 상품 항목 조회하기
const GET_INQUESTDETAIL = "GET_INQUESTDETAIL";
const GET_INQUESTDETAIL_SUCCESS = "GET_INQUESTDETAIL_SUCCESS";
const GET_INQUESTDETAIL_ERROR = "GET_INQUESTDETAIL_ERROR";

const CLEAR_INQUESTTLIST = "CLEAR_INQUESTTLIST";

export const getInquestList = (pageNumber) => ({
  type: GET_INQUESTLIST,
  payload: { page: pageNumber },
});

export const getInquestDetail = (access, id) => ({
  type: GET_INQUESTDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearInquestList = () => ({ type: CLEAR_INQUESTLIST });

const getInquestListSaga = createPromiseSagaList(
  GET_INQUESTLIST,
  inquestApi.getInquestList
);

const getInquestDetailSaga = createPromiseSagaDetail(
  GET_INQUESTDETAIL,
  inquestApi.getInquestDetail
);

// 사가들을 합치기
export function* inquestSaga() {
  yield takeEvery(GET_INQUESTLIST, getInquestListSaga);
  yield takeEvery(GET_INQUESTDETAIL, getInquestDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  inquestlist: reducerUtils.initial(),
  inquestdetail: reducerUtils.initial(),
};

export default function inquestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INQUESTLIST:
    case GET_INQUESTLIST_SUCCESS:
    case GET_INQUESTLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_INQUESTLIST,
        "inquestlist",
        true
      );
      return reducerList(state, action);

    case GET_INQUESTDETAIL:
    case GET_INQUESTDETAIL_SUCCESS:
    case GET_INQUESTDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_INQUESTDETAIL,
        "inquestdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_INQUESTTLIST:
      return {
        ...state,
        inquestlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
