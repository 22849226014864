import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InquestList } from "../components/InquestList";
import { getInquestList, clearInquestList } from "../modules/inquests";
import { useAuth } from "../contexts/auth/auth.context";

export const InquestListContainer = ({pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ page, setPage ] = useState(pageParam);
  const [ content, setContent ] = useState('');

  const { data, loading, error} = useSelector((state) => state.inquestReducer.inquestlist);
  const dispatch = useDispatch();

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onContentSubmit = (contentParam) => {
    setContent(contentParam);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getInquestList(page));
  }, [dispatch, page, content]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <InquestList 
    dataParam={data}
    currPage = {page}
    onPageSubmit={onPageSubmit}
    onContentSubmit={onContentSubmit}
    />
  );
}

