import axios from "./index";

// 수집품 데이터 목록// ?track_total_hits=true
export const getCollectList = async (params) => {
  const response = await axios.post("argus_op_product_productdetermined-*/_search?track_total_hits=true", params.data);
  return response.data.hits;
};

// 수집품 데이터 상세 정보
export const getCollectDetail = async (params) => {
  const response = await axios.post("argus_op_product_productdetermined-*/_search", params.data);
  return response.data.hits.hits;
};

class CollectApi {
  // 수집품 데이터 삭제
  static DeleteCollect = (params) => {
    const response = axios.post("argus_op_product_productdetermined-*/_delete_by_query", params);
    return response;
  };

  // 수집품 데이터 건수
  static getCollectCount = (params) => {
    const response = axios.post("argus_op_product_productdetermined-*/_count", params);
    return response;
  };
}
export default CollectApi;

