import {React, useState, useRef} from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const LeftMenuItem = ({ item, parent_name='' }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const refCollapsing = useRef(false);
  const [collapsed, setCollapsed] = useState(false);
  // const iconUpDown = collapsed ? <HiChevronUp /> : <HiChevronDown />;
  const navigate = useNavigate();

  function onCollapse(item, e) {
    // e.preventDefault();
    refCollapsing.current = true;
    setCollapsed(!collapsed)
    if (itemColapse(item) === false) {
      item.collapsed = true;
      setGlobal((global) => ({
        ...global,
        menuCategoryLevel: item.depth,
        menuCategoryCollapsed: item.collapsed,
        menuCategoryCode: item.code,
        menuCategoryName: item.name,
      }));
      navigate(`/${item.code}`);
    } else {
      item.collapsed = false;
      setGlobal((global) => ({
        ...global,
        menuCategoryLevel: item.depth,
        menuCategoryCollapsed: item.collapsed,
        menuCategoryCode: item.code,
        menuCategoryName: item.name,
      }));
    }
  }

  const onClick = (item, e) => {
    // e.preventDefault();
    // iconUpDown을 클릭한 경우 그냥 리턴
    if (refCollapsing.current === true) {
      refCollapsing.current = false;
      return;
    }

    // 선택된 카테고리 정보 컨텍스트에 저장
    if (item.depth === 1) {
      setCollapsed(!collapsed)
      item.collapsed = true;
      setGlobal((global) => ({
        ...global,
        menuCategoryLevel: item.depth,
        menuCategoryCollapsed: true,
        menuCategoryCode: item.code,
        menuCategoryName: item.name,
        menuCategoryCodeSub: '',
        menuCategoryNameSub: ''
      }));
    } else if (item.depth === 2) {
      setCollapsed(!collapsed)
      item.collapsed = true;
      setGlobal((global) => ({
        ...global,
        menuCategoryLevel: item.depth,
        menuCategoryCollapsed: true,
        menuCategoryCode: item.parent_code,
        menuCategoryName: parent_name,
        menuCategoryCodeSub: item.code,
        menuCategoryNameSub: item.name
      }));
    }
    navigate(`/${item.code}`);
  }

  function itemColor(item) {
    if (global.menuCategoryLevel === 1 && item.depth === global.menuCategoryLevel) {
      if (item.code === global.menuCategoryCode)
        return "tomato";
    } else if (global.menuCategoryLevel === 2 && item.depth === 2 && item.depth === global.menuCategoryLevel) {
      if (item.code === global.menuCategoryCodeSub)
        return "tomato";
    }
    return null;
  }

  function itemColapse(item) {
    if ((item.code === global.menuCategoryCode || item.code === global.menuCategoryCodeSub) && global.menuCategoryCollapsed == true) {
        return true;
    } else {
      return false;
    }
  }

  if(item.childrens.length > 0) {
    return (
      <div>
        {/* // 서브항목이 존재하는 서브메뉴 출력 */}
        <div className="left-menu-item">
          <div className="text-wrapper-2" style={{cursor: 'pointer', color:itemColor(item)}} onClick={(e) => onClick(item, e)}><p>{item.name}</p>
            <button className="button" onClick={(e) => onCollapse(item, e)}>
              <div className="symbol">{itemColapse(item) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
            </button>
          </div>
        </div>
        {/* // 서브메뉴 호출 */}
        <div style={{cursor: 'pointer', overflow: 'hidden',  maxHeight: itemColapse(item) ? "100%" : "0"}}>
          {item.childrens.map((child, index, total) => (
            <LeftMenuItem item={child} parent_name={item.name} key={index} />
          ))}
        </div>
      </div>
    )
  } else {
    return (
      // 리프항목 출력
      <div className="left-menu-item">
        <div className="text-wrapper-2" style={{cursor: 'pointer', color: itemColor(item) , marginLeft: `${((item.depth-1)*20)}px` }} onClick={(e) => onClick(item, e)}>{item.name}</div>
      </div>
    )
  }
}

export default LeftMenuItem;