import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MalsellerDetail } from "../components/MalsellerDetail";
import { getMalsellerDetail } from "../modules/malsellers";
import { useAuth } from "../contexts/auth/auth.context";

export const MalsellerDetailContainer = ({sellerParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ seller, setSeller ] = useState(sellerParam);
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.malsellerReducer.malsellerdetail);
  const dispatch = useDispatch();

  const onSellerSubmit = (sellerParam) => {
    setPage(1);
    setSeller(sellerParam);
  }

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getMalsellerDetail(seller, page));
  }, [dispatch, seller, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <MalsellerDetail 
    dataParam={data}
    currPage = {pageParam}
    onPageSubmit={onPageSubmit} 
    onSellerSubmit={onSellerSubmit} 
    />
  );
}

