import React, {useState, useContext} from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AlertContext from '../../contexts/alert/AlertContext';

export const SignupAgree = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category")

  const [ allAgreed, setAllAgreed ] = useState(false) 
  const [ agreements, setAgreements] = useState({
    termsAgreed: false,
    personalPrivacy: false,
  });
  const { alert: alertComp } = useContext(AlertContext);

  const handleAgreementChange = (event) => {
    const { name, checked } = event.target;
   
    setAgreements((prevAgreements) => ({ ...prevAgreements, [name]: checked }));
    const allChecked = Object.values({ ...agreements, [name]: checked }).every(
      (value) => value === true
    );
    setAllAgreed(allChecked);
  };  

  
  const handleAllAgreementChange = (event) => {
    const { name, checked } = event.target;
    setAgreements((prevAgreements) =>
      Object.keys(prevAgreements).reduce(
        (newAgreements, agreementKey) => ({
          ...newAgreements,
          [agreementKey]: checked,
        }),
        {}
      )
    );
    setAllAgreed(checked);
  };

  const onClick = (paramCode, e) => {
    e.preventDefault();
    if (paramCode == 'confirm') {
      if (allAgreed) {
        navigate(`/signupregistry?category=${categoryParam}`);
      }
      else if (!agreements.termsAgreed) {
        const result = alertComp("이용 약관에 동의 해야 합니다.");
      }
      else if (!agreements.personalPrivacy) {
        const result = alertComp("개인정보처리방침에 동의 해야 합니다.");
      }
    }
    else {
      navigate(-1); // 이전 페이지로 이동
    }
  }

  return (
    <div className="signup-agree">
      <div className="div-4">
        <div className="middle-section-2">
          <LeftMenu className="design-component-instance-node" />
          <div className="body-container">
            <div className="registry-title-text-wrapper">
              <p className="p">약관 및 개인정보 활용 동의</p>
            </div>

            <div className="title-container">
              <div className="div-agree-all-txt">
                <div className="logo-png" />

                <div className="p-point">
                  <p className="div-5">
                    <span className="span">
                      본 회원은 약관, 동의서, 유의사항, 관리규정을 모두
                      이해하였습니다. 아래의 사항에 일괄 동의합니다.
                      <br />
                    </span>

                    <span className="text-wrapper-12">
                      ※ 각 항목에 대해 개별적으로 동의를 선택하실 수 있습니다.
                    </span>
                  </p>
                </div>
              </div>

              <div className="p-join-check">
                {/* <div className="check-box">[]</div>
                <div className="text-wrapper-13">일괄 동의</div> */}
                <input className="check-box" type="checkbox" id="agree_check_all" name="agree_check_all" required onChange={handleAllAgreementChange} checked={allAgreed} style={{cursor:"pointer", top:"2px"}}/>
                <label className="text-wrapper-13" style={{cursor:"pointer"}}>일괄동의</label>


              </div>


            </div>

            <div className="agree-container">
              <div className="heading">이용약관</div>

              <div className="div-con">
                <div className="list-item">
                  <p className="element">
                    <span className="text-wrapper-14">
                      제 1장 총칙 <br />
                    </span>

                    <span className="text-wrapper-15">
                      {" "}
                      <br />
                    </span>

                    <span className="text-wrapper-14">
                      제2조 (약관의 효력 및 변경) <br />
                    </span>

                    <span className="text-wrapper-15">
                      {" "}
                      <br />
                    </span>

                    <span className="text-wrapper-14">
                      제1조 (목적) <br />
                    </span>

                    <span className="text-wrapper-15">
                      {" "}
                      <br />
                    </span>

                    <span className="text-wrapper-16">
                      본 약관은 리팡아거스(이하 “회사”라함)가 운영하는 모바일
                      컨텐츠 전자상거래 서비스인 차가운(이하 “본서비스”라함)를
                      이용함에 있어 디지털 컨텐츠를 거래하는 회원 간의 기본적인
                      사항을 규정하고, 회원 또는 회원과 회사간의 권리, 의무,
                      책임사항 및 서비스 이용조건을 규정함으로써 회원의 권익을
                      보호하는 것을 목적으로 합니다. <br />
                    </span>

                    <span className="text-wrapper-15">
                      {" "}
                      <br />
                    </span>
                  </p>
                </div>
              </div>

              <div className="p-join-check-2">
                {/* <div className="check-box">[]</div>
                <p className="text-wrapper-17">
                  {" "}
                  위의 이용약관 에 동의 합니다. (필수 동의)
                </p> */}
                <input className="check-box" type="checkbox" id="term_condition" name="termsAgreed" required onChange={handleAgreementChange} checked={agreements.termsAgreed} style={{cursor:"pointer", top:"2px"}}/>
                <label className="text-wrapper-17" htmlFor="term_condition" style={{cursor:"pointer"}}> 위의 이용약관 에 동의 합니다. (필수 동의)</label>

              </div>
            </div>

            <div className="personal-container">
              <div className="heading-2">개인정보처리방침</div>

              <div className="list-item-wrapper">
                <div className="term-wrapper">
                  <div className="term">
                    <div className="p-2">
                      <p className="text-wrapper-18">
                        &#34;(주리팡아거스&#34;는 (이하 &#39;회사&#39;는)
                        고객님의 개인정보를 중요시하며, &#34;정보통신망 이용촉진
                        및 정보보호&#34;에 관한 법률을 준수하고 있습니다. 회사는
                        개인정보처리방침을 통하여 고객님께서 제공하시는
                        개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
                        개인정보보호를 위해 어떠한 조치가 취해지고 있는지
                        알려드립니다. 회사는 개인정보처리방침을 개정하는 경우
                        웹사이트 공지사항(또는 개별공지)을 통하여 공지할
                        것입니다.
                      </p>
                    </div>

                    <p className="text-wrapper-19">
                      본 방침은 : 2025 년 01 월 1 일 부터 시행됩니다.
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-join-check-3">
                {/* <div className="check-box">[]</div>
                <p className="text-wrapper-20">
                  {" "}
                  개인정보처리방침에 동의 합니다. (필수 동의)
                </p> */}
                <input className="check-box" type="checkbox" id="personal_privacy" name="personalPrivacy" required onChange={handleAgreementChange} checked={agreements.personalPrivacy} style={{cursor:"pointer", top:"2px"}}/>
                <label className="text-wrapper-20" htmlFor="personal_privacy" style={{cursor:"pointer"}}> 개인정보처리방침에 동의 합니다. (필수 동의)</label>

              </div>
            </div>

            <div className="button-container-2">
              <div className="link-4" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('confirm', e)}}>
                <div className="text-wrapper-21">확인</div>
              </div>

              <div className="link-5" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('backward', e)}}>
                <div className="text-wrapper-22">이전으로</div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-section-2">
          <MainLogo className="main-logo-2" />
          <RightMenu className="right-menu-2" />
        </div>
      </div>
    </div>
  );
};
