import axios from "./index";

export const getDeterminedList = async (params) => {
  const response = await axios.get("/determineds/", { params: params });
  return response.data;
};
export const getDeterminedDetail = async (id) => {
  const response = await axios.get(`/determineds/${id}/`);
  return response.data;
};

class DeterminedApi {
  // 판별품 데이터 생성
  static InsertDetermined = (formData) => {
    return axios.post(`/determineds/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };
  
  // 판별품 데이터 수정
  static UpdateDetermined = (id, formData) => {
    let data = `/determineds/${id}/`
    return axios.post(`/determineds/${id}/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  // 이 수집품은 삭제되었음을 서버에 전송
  static InsertConfirmed = (formData) => {
    return axios.post(`/confirmed/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };


  
}
export default DeterminedApi;