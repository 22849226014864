import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ContentList } from "../components/ContentList";
import { getContentList } from "../modules/contents";
import { useAuth } from "../contexts/auth/auth.context";

export const ContentListContainer = ({typeParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  // const [ type, setType ] = useState(typeParam);
  // const [ searchType, setSearchType ] = useState(searchTypeParam);
  // const [ search, setSearch ] = useState(searchParam);
  // const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.contentReducer.contentlist);
  const dispatch = useDispatch();

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParam, searchParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearch(searchParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getContentList(typeParam, searchTypeParam, searchParam, pageParam));
  }, [dispatch, typeParam, searchTypeParam, searchParam, pageParam]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <ContentList 
    dataParam={data}
    currPage = {pageParam}
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

