import React, { useState, useRef } from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import ProductApi from "../../api/products";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import { actives, activeOptions } from "../../config/codes";

export const ProductDetail = ({prd}) => {
  const navigate = useNavigate();

  // 브랜드
  const brandRef = useRef();
  const [brand, setBrand] = useState(prd.brand);
  const handleBrandChange = (e) => {
    let value = e.target.value;
    setBrand(value);
  };

  // 모델
  const modelRef = useRef();
  const [model, setModel] = useState(prd.model);
  const handleModelChange = (e) => {
    let value = e.target.value;
    setModel(value);
  };

  // 카테고리
  const categoryRef = useRef();
  const [category, setCategory] = useState(prd.category);
  const handleCategoryChange = (e) => {
    let value = e.target.value;
    setCategory(value);
  };

  // 상품제목
  const productNameRef = useRef();
  const [productName, setProductName] = useState(prd.product_name);
  const handleProductNameChange = (e) => {
    let value = e.target.value;
    setProductName(value);
  };

  // 키워드
  const keywordsRef = useRef();
  const [keywords, setKeywords] = useState(prd.keywords);
  const handleKeywordsChange = (e) => {
    let value = e.target.value;
    setKeywords(value);
  };

  // 화폐단위
  const currencyRef = useRef();
  const [currency, setCurrency] = useState(prd.currency);
  const handleCurrencyChange = (e) => {
    let value = e.target.value;
    setCurrency(value);
  };

  // 기준가격
  const firstPriceRef = useRef();
  const [firstPrice, setFirstPrice] = useState(prd.first_price);
  const handleFirstPriceChange = (e) => {
    let value = e.target.value;
    setFirstPrice(value);
  };

  // 최종가격
  const finalPriceRef = useRef();
  const [finalPrice, setFinalPrice] = useState(prd.final_price);
  const handleFinalPriceChange = (e) => {
    let value = e.target.value;
    setFinalPrice(value);
  };

  // 정품하한
  const priceFloorRef = useRef();
  const [priceFloor, setPriceFloor] = useState(prd.price_floor);
  const handlePriceFloorChange = (e) => {
    let value = e.target.value;
    setPriceFloor(value);
  };

  // 상품 내용
  const contentRef = useRef();
  const [content, setContent] = useState(prd.content);
  const handleContentChange = (e) => {
    let value = e.target.value;
    setContent(value);
  };

  // 썸네일01
  const thumb1Ref = useRef();
  const [thumb1, setThumb1] = useState(prd.thumb1);
  const handleThumb1Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      thumb1Ref.current = file;
      setThumb1(file.name);
    }
  };


  // 썸네일02
  const thumb2Ref = useRef();
  const [thumb2, setThumb2] = useState(prd.thumb2);
  const handleThumb2Change = (e) => {
    let file = e.target.files[0];
    setThumb2(file.name);
  };

  // 썸네일03
  const thumb3Ref = useRef();
  const [thumb3, setThumb3] = useState(prd.thumb3);
  const handleThumb3Change = (e) => {
    let file = e.target.files[0];
    setThumb3(file.name);
  };

  // 썸네일04
  const thumb4Ref = useRef();
  const [thumb4, setThumb4] = useState(prd.thumb4);
  const handleThumb4Change = (e) => {
    let file = e.target.files[0];
    setThumb4(file.name);
  };

  // 썸네일05
  const thumb5Ref = useRef();
  const [thumb5, setThumb5] = useState(prd.thumb5);
  const handleThumb5Change = (e) => {
    let file = e.target.files[0];
    setThumb5(file.name);
  };

  // 바디이미지01
  const image1Ref = useRef();
  const [image1, setImage1] = useState(prd.image1);
  const handleImage1Change = (e) => {
    let file = e.target.files[0];
    setImage1(file.name);
  };

  // 바디이미지02
  const image2Ref = useRef();
  const [image2, setImage2] = useState(prd.image2);
  const handleImage2Change = (e) => {
    let file = e.target.files[0];
    setImage2(file.name);
  };

  // 바디이미지03
  const image3Ref = useRef();
  const [image3, setImage3] = useState(prd.image3);
  const handleImage3Change = (e) => {
    let file = e.target.files[0];
    setImage3(file.name);
  };

  // 바디이미지04
  const image4Ref = useRef();
  const [image4, setImage4] = useState(prd.image4);
  const handleImage4Change = (e) => {
    let file = e.target.files[0];
    setImage4(file.name);
  };

  // 바디이미지05
  const image5Ref = useRef();
  const [image5, setImage5] = useState(prd.image5);
  const handleImage5Change = (e) => {
    let file = e.target.files[0];
    setImage5(file.name);
  };

  // 거래처
  const customerIdRef = useRef();
  const [customerId, setCustomerId] = useState(prd.customer_id);
  const handleCustomerIdChange = (e) => {
    let value = e.target.value;
    setCustomer(value);
  };

  const handleFileSelectClick = (e, ref) => {
    ref.current.click();
  }

  const onDelete = (e) => {
    e.preventDefault();

    // 지재권 데이터 재거 서버에 전송
    ProductApi.DeleteProduct(id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`상품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })    
  }

  // validate Input Columns
  const validateInputs = () => {
    if (brand.length <= 0) {
      const result = alert("브랜드명을 입력해 주세요!");
      return false;
    }
    else if (model.length <= 0) {
      const result = alert("모델명을 입력해 주세요!");
      return false;
    }
    else if (category.length <= 0) {
      const result = alert("카테고리를 입력해 주세요!");
      return false;
    }
    return true;
  }

  // 이전 페이지로
  const handleUpdate = (e) => {
    e.preventDefault();
    if (validateInputs() === true) {
      let formData = new FormData();
      formData.append("brand", brand);
      formData.append("model", model);
      formData.append("category", category);
      formData.append("product_name", productName);
      formData.append("keywords", keywords);
      formData.append("currency", currency);
      formData.append("first_price", firstPrice);
      formData.append("final_price", finalPrice);
      formData.append("price_floor", priceFloor);
      formData.append("content", content);
      formData.append("thumb1", thumb1Ref.current); //e.target.files[0]
      // formData.append("image1", image1);
      formData.append("customer_id", customerId);
      formData.append("active", active);

      // formData.append(`determin_image${index+1}`, new File([blob], determin_file));      
  
      ProductApi.UpdateProduct(prd.id, formData).then(response => {
        if(response.status == 200 || response.status == 201) {
          alert("정상적으로 저장되었습니다.")
        } else {
          alert(response.statusText)
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      })    
    }
  }


  // 이전 페이지로
  const handleBackward = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const [active, setActive] = useState(prd.active);
  const handleSelectActiveChange = (e) => {
    let value = e.target.value;
    setActive(value);
  };

  return (
    <div className="product-detail">
      <div className="div-2">
        <div className="body-frame">
          <div className="bulletin-body-title">
            <div className="title">상품 상세</div>
          </div>

          <div className="body-sub-title" />

          <div className="container">
            <div className="form">
              <div className="fieldset">
                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">브랜드:</div>
                    </div>

                    {/* <div className="data-frame">
                    <div className="text-wrapper-7">{prd.brand}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="brand" value={brand} onChange={(e) => handleBrandChange(e)} ref={brandRef}/>


                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">모델:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.model}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="model" value={model} onChange={(e) => handleModelChange(e)} ref={modelRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">카테고리:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.category}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="category" value={category} onChange={(e) => handleCategoryChange(e)} ref={categoryRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">상품 제목:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.product_name}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="productName" value={productName} onChange={(e) => handleProductNameChange(e)} ref={productNameRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">검색 키워드:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.keywords}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="keywords" value={keywords} onChange={(e) => handleKeywordsChange(e)} ref={keywordsRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">화폐단위:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.currency}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="currency" value={currency} onChange={(e) => handleCurrencyChange(e)} ref={currencyRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">기준 가격:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.first_price}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="firstPrice" value={firstPrice} onChange={(e) => handleFirstPriceChange(e)} ref={firstPriceRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">최종 가격:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.final_price}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="finalPrice" value={finalPrice} onChange={(e) => handleFinalPriceChange(e)} ref={finalPriceRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">정품 하한:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.price_floor}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="priceFloor" value={priceFloor} onChange={(e) => handlePriceFloorChange(e)} ref={priceFloorRef}/>

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-8">내용:</div>
                    </div>

                    {/* <div className="data-frame-2">
                      <div className="text-wrapper-9">{prd.content}</div>
                    </div> */}
                    {/* <textarea className="data-frame" type="text" name="content" value={content} onChange={(e) => handleContentChange(e)} ref={contentRef}/> */}
                    <textarea className="data-frame" name="content" value={content} ref={contentRef} style={{width:"610px", height:"300px"}} onChange={(e) => handleContentChange(e)} placeholder="조사 요청 내용을 입력해 주세요."></textarea>

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일01:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb1}</div>
                    </div>

                    <div className="preview-wrapper">
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb1})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, thumb1Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={thumb1Ref} onChange={handleThumb1Change} style={{ display: "none" }} />

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일02:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb2}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb2})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, thumb2Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={thumb2Ref} onChange={handleThumb2Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일03:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb3}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb3})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, thumb3Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={thumb3Ref} onChange={handleThumb3Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일04:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb4}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb4})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, thumb4Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={thumb4Ref} onChange={handleThumb4Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일05:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb5}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb5})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, thumb5Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={thumb5Ref} onChange={handleThumb5Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지01:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image1}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image1})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, image1Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={image1Ref} onChange={handleImage1Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지02:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image2}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image2})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, image2Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={image2Ref} onChange={handleImage2Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지03:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image3}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image3})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, image3Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={image3Ref} onChange={handleImage3Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지04:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image4}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image4})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, image4Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={image4Ref} onChange={handleImage4Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지05:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image5}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image5})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, image5Ref)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" ref={image5Ref} onChange={handleImage5Change} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">거래처 ID:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.customer_id}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="customerId" value={customerId} onChange={(e) => handleCustomerIdChange(e)} ref={customerIdRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">활성여부:</div>
                    </div>

                    <div className="data-frame" style={{width:'100px'}}>
                      {/* <div className="text-wrapper-7">{prd.active}</div> */}
                      <select className="text-wrapper-7" id="active" name="active" value={active} onChange={handleSelectActiveChange} style={{border:"none"}}>
                        { activeOptions.map((option, index, total) => ( 
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>


                    </div>
                  </div>
                </div>
              </div>

              <div className="field-summary">
                <div className="list-frame" style={{cursor:'pointer'}} onClick={(e)=>{handleDelete(e)}}>
                  <div className="text-wrapper-12">삭제</div>
                </div>

                <div className="list-frame" style={{cursor:'pointer'}} onClick={(e)=>{handleUpdate(e)}}>
                  <div className="text-wrapper-12">수정</div>
                </div>

                <div className="list-frame-2" style={{cursor:'pointer'}} onClick={(e)=>{handleBackward(e)}}>
                  <div className="text-wrapper-13">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LeftMenu className="left-menu-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
