import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
import ProductApi from "../../api/products";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const ProductList = ({ dataParam, brandParam, searchTypeParam, searchTextParam, pageParam, onPageSubmit, onBrandSubmit, onSearchTextSubmit }) => {  
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": pageParam, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 검색 옵션
  const searchsDict = [
    { value: '', label: '전체' },
    { value: 'product_name', label: '제품명' },
    { value: 'keywords', label: '키워드' },
  ];

  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 브랜드 코드 구하기기
  const PullBrandCode = () => {
    let formData = { include: 'all' }
    ProductApi.getBrandCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setBrand(value);
        setBrands(response.data.list);
        // refBrand.current = value;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 브랜드
  const [brand, setBrand] = useState(brandParam);
  const [brands, setBrands] = useState([])
  const handleBrand = (e) => {
    let value = e.target.value;
    setBrand(value);
    onBrandSubmit(value);
    setPage(1);
  };

  // 페이지 이동
  const [page, setPage] = useState(pageParam);


  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;    
  }

  useEffect(() => {
    // 브랜드
    PullBrandCode();

  },[]);

  return (
    <div className="product-list">
      <div className="div-2">
        <div className="bulletin-body">
          <div className="body">
            <div className="body-title">
              <div className="title">정품정보</div>
            </div>

            <div className="body-sub-title" />

            <div className="body-head">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-8">브랜드</div>
                </div>

                <div className="element-wrapper">
                  {/* <div className="element">저작권</div> */}
                  <select className="element" style={{width:"80px", height:"25px", border:"none"}} id="brand" name="brand" value={brand} onChange={(e) => handleBrand(e)}>
                    { brands.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="drop-downs">
                <div className="dropdown" style={{border:'1px solid', borderColor:'lightgray'}}>
                  {/* <div className="text-wrapper-8">브랜드</div> */}
                  <select className="text-wrapper-8" style={{width:"80px", height:"25px", border:"none", textAlign:'left'}} id="searchType" name="searchType" value={searchType} onChange={(e) => handleSearchType(e)}>
                    { searchsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>

                <div className="element-wrapper">
                  {/* <div className="element">저작권</div> */}
                  <input className="element" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="검색" style={{width:"80px", height:"25px", border:"none"}}/>

                </div>
              </div>
            </div>

            <div className="body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-9">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-10">이미지</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-10">카테고리</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-9">브랜드</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-9">모델</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-11">제품명</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-9">키워드</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-9">최종가격</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-9">정품하한</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-9">거래처</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-9">생성일</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/productdetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-12">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="thumb-image-wrapper">
                    {/* <img className="thumb-image" alt="Thumb image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6795afeedb431e6a79d3bd95/img/thumbimage@2x.png" /> */}
                    <div className="thumb-image" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${prd.thumb1})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center', border:'1px solid', borderColor:'lightgray' }}/>


                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-12">{prd.category}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.brand}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.model}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-12">{prd.product_name.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-12">{prd.keywords.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.final_price.toLocaleString('ko-KR')}({prd.currency})</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.price_floor.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-12">{prd.cust_name}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.created_at ? prd.created_at.substr(0,10) : null}</div>
                  </div>

                </Link>
                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="body-page-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>

        <LeftMenu className="left-menu-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
