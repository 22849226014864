import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LawsuitList } from "../components/LawsuitList";
import { getLawsuitList } from "../modules/lawsuits";
import { useAuth } from "../contexts/auth/auth.context";

export const LawsuitListContainer = ({platformParam = '', progressParam = '', toDateParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ platform, setPlatform ] = useState(platformParam);
  const [ progress, setProgress ] = useState(progressParam);
  const [ toDate, setToDate ] = useState(toDateParam);
  const [ searchType, setSearchType ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.lawsuitReducer.lawsuitlist);
  const dispatch = useDispatch();

  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }


  const onProgressSubmit = (progressParam) => {
    setPage(1);
    setProgress(progressParam);
  }


  const onToDateSubmit = (toDateParam) => {
    setPage(1);
    setToDate(toDateParam);
  }


  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParam, searchParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearch(searchParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getLawsuitList(platform, progress, toDate, searchType, search, page));
  }, [dispatch, platform, progress, toDate, searchType, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <LawsuitList 
    dataParam={data}
    currPage = {page}
    onPlatformSubmit={onPlatformSubmit} 
    onProgressSubmit={onProgressSubmit} 
    onToDateSubmit={onToDateSubmit} 
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}