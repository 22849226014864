import React from "react";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";

export const MonitoringDetail = () => {
  return (
    <div className="monitoring-detail">
      <div className="div-3">
        <div className="body-wrapper">
          <div className="body-2">
            <div className="title-wrapper">
              <div className="title-2">모니터링 세부현황</div>
            </div>

            <div className="body-sub-title-2" />

            <div className="body-head-2">
              <div className="drop-downs-2">
                <div className="dropdown-4">
                  <div className="text-wrapper-14">플랫폼</div>
                </div>

                <div className="dropdown-5">
                  <div className="text-wrapper-15">저작권</div>
                </div>

                <div className="dropdown-6">
                  <div className="text-wrapper-14">카테고리</div>
                </div>

                <div className="dropdown-5">
                  <div className="text-wrapper-15">저작권</div>
                </div>

                <div className="dropdown-6">
                  <div className="text-wrapper-14">침해유형</div>
                </div>

                <div className="dropdown-5">
                  <div className="text-wrapper-15">저작권</div>
                </div>
              </div>

              <BodySearch className="design-component-instance-node" />
            </div>
          </div>
        </div>

        <FooterPanel className="footer-panel-2" />
        <MainLogo className="main-logo-2" />
        <RightMenu className="right-menu-2" />
        <LeftMenu className="left-menu-2" />
      </div>
    </div>
  );
};
