import React from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import { FooterPanel } from "../../components/FooterPanel";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import IprApi from "../../api/iprs";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const IprDetail = ({ipr}) => {
  const navigate = useNavigate();

  const onDelete = (id, e) => {
    e.preventDefault();

    // 지재권 데이터 재거 서버에 전송
    IprApi.DeleteIpr(id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`지재권 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })    
  }
  
  const onSave = (id, e) => {
    e.preventDefault();
  }
    
  return (
    <div className="ipr-detail">
      <div className="div-2">
        {/* <FooterPanel className="footer-panel-instance" /> */}
        <div className="body-frame">
          <div className="bulletin-body-title">
            <div className="title">등록 지재권</div>
          </div>

          <div className="body-sub-title" />

          <div className="body-title-frame">
            <div className="text-wrapper-14">{ipr.ipr_name}</div>
          </div>

          <div className="container">
            <div className="form">
              <div className="fieldset">
                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">브랜드:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.brand}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">국가:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.nation}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">지재권명칭:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.ipr_name}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">승인번호:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.license_no}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">지재권유형:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.ipr_type}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">출원(등록)번호:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.apply_no}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">권리인:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.right_holder}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-15">지재권등록증:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.ipr_image}</div>
                    </div>

                    {/* <div className="data-frame-3">
                      <div className="text-wrapper-17"></div>
                    </div> */}
                    <a className="data-frame-3" href={`${BACKEND_SERVER_BASEURL}${ipr.ipr_image}`} style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${ipr.ipr_image})`, backgroundSize:'cover'}}/>

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-18">비고:</div>
                    </div>

                    <div className="data-frame-4">
                      <div className="text-wrapper-17" dangerouslySetInnerHTML={{__html: ipr.comment}}></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">출원일자:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.applied_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-15">유효일자:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-16">{ipr.expired_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-16"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field-summary">
                <div className="list-frame" style={{cursor:'pointer'}} onClick={(e) => {navigate(-1)}}>
                  <div className="text-wrapper-19">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="left-menu-instance" />
      </div>
    </div>
  );
};
