import axios from "./index";

export const getForgeryList = async (params) => {
  const response = await axios.get("/forgerys/", { params: params });
  return response.data;
};
export const getForgeryDetail = async (formData) => {
  const response = await axios.get(`/forgerys/${formData.id}/`);
  return response.data;
};
export const getForgeryReport = async (formData) => {
  const response = await axios.get(`/forgerys/${formData.id}/`);
  return response.data;
};

class ForgeryApi {
  // 판별품 데이터 생성
  static InsertForgery = (formData) => {
    return axios.post(`/productforgerys/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  // 위조품 데이터 재거
  static DeleteForgery = (id) => {
    const data = `/forgerys/${id}/`;
    const response = axios.delete(`/forgerys/${id}/`);
    return response;
  };  
  
}
export default ForgeryApi;