import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Password } from "../components/Password";


export const PasswordContainer = () => {
  return (
    <Password />
  );
}

