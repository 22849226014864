import { useParams } from "react-router";
import { ProductDetailContainer } from "../../containers/ProductDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";

export const ProductDetailPage = () => {
  const { id } = useParams();  

  return (
    (!isNaN(+id) === true) ?
    <ProductDetailContainer id={parseInt(id)} /> :
    <ProductListContainer/>
  );
}
