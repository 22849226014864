import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";

import { Link, useNavigate } from "react-router-dom";
import InquestApi from "../../api/inquests";
import { useAuth } from "../../contexts/auth/auth.context";


export const InquestList = ({ dataParam, currPage, onPageSubmit, onContentSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const { user, setUser, global, setGlobal } = useAuth();
  const [ inquestId, setInquestId ] = useState(0);
  const navigate = useNavigate();
  const countPerPage = 10;

  // 처리결과  
  const statuses = { request : "요청중", 	finished : "처리완료" }

  // validate Input Columns
  const validateInputs = () => {
    if (content.length <= 0) {
      const result = alert("조사 요청 내용을 기술해 주세요!");
      refContent.current.focus();
      return false;
    }
    else if (email.length <= 0) {
      const result = alert("이메일을 입력해 주세요!");
      return false;
    }
    return true;
  }

  // Content  
  const refContent = useRef();
  const [content, setContent] = useState('');
  const handleContentChange = (e) => {
    let value = e.target.value;
    setContent(value);
  };

  // 이메일  
  const [email, setEmail] = useState('');
  const handleEmailChange = (e) => {
    let value = e.target.value;
    setEmail(value);
  };

  const handleInquestSubmit = (e) => {
    e.preventDefault();
    if ( !user || user.id <= 0) {
      const result = alert("조사 요청을 작성하기 위해서는 로그인 인증이 필요합니다!");
      return;
    }
    if (validateInputs() === false) {
      return;
    }

    let formData = new FormData();
    formData.append("content", content);
    formData.append("email", email);
    
    InquestApi.insertInquest(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("조사 요청이 저장되었습니다.")
        navigate(`/productlist`);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  };

  const handleInquestNew = (e) => {
    e.preventDefault();
    setInquestId(0);
    setEmail(user.email);
    setContent('');
  };

  const handleInquestDel = (e) => {
    e.preventDefault();
    if (user && user.id > 0 && email && email === user.email && inquestId > 0) {
      // 조사요청 데이터 재거 전송
      let formData = new FormData();
      formData.append("id", inquestId);
      InquestApi.deleteInquest(formData).then(response => {
        if(response.status >= 200 || response.status <= 204) {
          setInquestId(0);
          setContent('');
          alert(`조사요청 데이터가 삭제되었습니다.`);
          onContentSubmit(content);
        } else {
          alert(response.statusText)
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      })
    }
  };

  const handleInquestEdit = (e, idParam, contentParam, emailParam) => {
    e.preventDefault();
    setInquestId(idParam);
    setContent(contentParam);
    setEmail(emailParam);
  };

  useEffect(() => {
    setEmail((user && user.id > 0) ? user.email : '');
  }, []);
  
  function formatEmailSameLength(emilString){
    var splitEmail = emilString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return  name.substring(0,2).concat(Array(name.length-1).join("*")).concat("@").concat(domain)
  }

  function SequenceNumber(currIndex) {
    return (currPage-1) * countPerPage + currIndex;
  }

  return (
    <div className="inquest-list">
      <div className="div-2">
        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>

        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-area">
            <div className="inquest-info">
              <div className="inquest-container">
                <div className="title-wrapper">
                  <div className="title">조사 요청</div>
                </div>

                <div className="info-body">
                  <div className="info-text">
                    <div className="sellername-edit">
                      <p className="p">
                        주력 제품 가격 및 제품 정보가 업데이트 되었다면 알려
                        주세요~!
                      </p>
                    </div>
                  </div>

                  <div className="seller-type-edit-wrapper">
                    <div className="seller-type-edit">
                      {/* <div className="text-wrapper-8">저작권</div> */}
                      <textarea className="text-wrapper-8" name="content" value={content} ref={refContent} style={{border:"none", height:"180px"}} onChange={(e) => handleContentChange(e)} placeholder="조사 요청 내용을 입력해 주세요."></textarea>

                    </div>
                  </div>

                  <div className="info-url">
                    <div className="info-url-text" style={{visibility:"hidden"}} >
                      <div className="text-wrapper-9">이메일</div>
                    </div>

                    <div className="info-url-edit" style={{visibility:"hidden"}} >
                      {/* <div className="text-wrapper-8">user@argus1365.com</div> */}
                      <input className="text-wrapper-8" type="text" name="email" value={email} onChange={(e) => handleEmailChange(e)} style={{border:"none"}}/>

                    </div>

                    <div className="info-save" style={{cursor:"pointer"}} onClick={(e) => handleInquestSubmit(e)} >
                      <div className="text-wrapper-10">제출</div>
                    </div>

                    <div className="div-wrapper-2" style={{cursor:"pointer"}} onClick={(e) => handleInquestNew(e)}>
                      <div className="text-wrapper-9">New</div>
                    </div>

                    <div className="div-wrapper-2" style={{cursor:"pointer"}} onClick={(e) => handleInquestDel(e)}>
                      <div className="text-wrapper-9">Del</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-seperator" />

            <div className="title-wrapper">
              <div className="title">업데이트 내역</div>
            </div>

            <div className="inquest-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-11">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-11">내용</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-11">플랫폼</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-11">이메일</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-11">생성일</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-11">결과</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <div className="record-frame"  key={prd.id} >

                  <div className="body-row-data" style={{backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-12">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2" style={{cursor:"pointer", backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-13" onClick={(e) => handleInquestEdit(e, prd.id, prd.content, prd.email)}>{prd.content}</div>
                  </div>

                  <div className="body-row-data-3" style={{backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-12">{prd.platform}</div>
                  </div>

                  <div className="body-row-data-4" style={{backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-13">{prd.email ? formatEmailSameLength(prd.email) : null}</div>
                  </div>

                  <div className="body-row-data-5" style={{backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-13">{prd.updated_at.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-5" style={{backgroundColor: (inquestId===prd.id) ? "#bcbcbc" : null}}>
                    <div className="text-wrapper-13">{prd.status}</div>
                  </div>

                </div>
                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="body-page-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} countPerPage={countPerPage} />
            :<></>}

          </div>
        </div>
      </div>
    </div>
  );
};
