import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SellerApi from "../../api/sellers";
import CodeApi from "../../api/codes";

import { risks, riskOptions } from "../../config/codes";
import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { reportStatuses, reportStatusOptions } from "../../config/codes";


export const MalsellerDetail = ({ dataParam, currPage, onPageSubmit, onSellerSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  const [searchParams, setSearchParams] = useSearchParams();
  const sellerParam = searchParams.get("seller")

  // 판매자 정보
  const [sellerInfo, setSellerInfo] = useState({});

  // 플랫폼
  const [platform, setPlatform] = useState('');
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 위험도
  const [risk, setRisk] = useState('');
  const handleRisk = (e) => {
    let value = e.target.value;
    setRisk(value);
    onRiskSubmit(value);
  };

  // 판매상명
  const [sellerName, setSellerName] = useState('');
  const handleSellerNameChange = (e) => {
    let value = e.target.value;
    setSellerName(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchSubmit(sellerName);
    }
  } 

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 브랜드 코드 구하기기
  const PullSellerInfo = (sellerParam) => {
    const formData = { seller: sellerParam }
    SellerApi.getSellerInfos(formData).then(response => {
      if(response.status === 200) {
        setSellerInfo(response.data[0]);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    PullSellerInfo(sellerParam);
    PullComCode('platform', '', 0);
  },[]);

  return (
    <div className="malseller-detail">
      <div className="div-2">
        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>

        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-area">
            <div className="seller-info">
              <div className="seller-info-2">
                <div className="title-wrapper">
                  <div className="title">판매상 정보</div>
                </div>

                <div className="info-body">
                  <div className="div-3">
                    <div className="seller-name-text">
                      <div className="text-wrapper-8">판매상명</div>
                    </div>

                    <div className="sellername-edit">
                      <div className="text-wrapper-9">{sellerInfo ? sellerInfo.seller_id : null}</div>
                    </div>
                  </div>

                  <div className="div-3">
                    <div className="seller-type-text">
                      <div className="text-wrapper-8">판매상 유형</div>
                    </div>

                    <div className="seller-type-edit">
                      <div className="text-wrapper-9">{sellerInfo ? sellerInfo.business_type : null}</div>
                    </div>
                  </div>

                  <div className="div-3">
                    <div className="seller-type-text">
                      <div className="text-wrapper-8">기업명</div>
                    </div>

                    <div className="seller-type-edit">
                      <div className="text-wrapper-9">{sellerInfo ? sellerInfo.company : null}</div>
                    </div>
                  </div>

                  <div className="div-3">
                    <div className="seller-type-text">
                      <div className="text-wrapper-8">사업자등록번호</div>
                    </div>

                    <div className="seller-type-edit">
                      <div className="text-wrapper-9">{sellerInfo ? sellerInfo.business_no : null}</div>
                    </div>
                  </div>

                  <div className="div-3">
                    <div className="seller-type-text">
                      <div className="text-wrapper-8">위험도</div>
                    </div>

                    <div className="seller-type-edit">
                      <div className="text-wrapper-9">{sellerInfo ? risks[sellerInfo.risk] : null}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-seperator" />

            <div className="title-wrapper">
              <div className="title">판매상의 제품</div>
            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-10">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-10">이미지</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-10">제품명</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">플랫폼</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-11">가격</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">판매량</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">침해유형</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">신고일자</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">신고결과</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">판매상위험도</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-10">비고</div>
                  </div>
                </div>

                {datas ? datas
                // .filter((dtm) => risk.length === 0 || dtm.risk === risk)
                .map((dtm, index, total) => (
                <Link className="record-frame" to={`/reports/${dtm.id}`} key={dtm.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-12">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-13">이미지</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{dtm.determin_name.substring(0,20)}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{platformsDict[dtm.platform]}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-12">{dtm.determin_price}({dtm.currency})</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{dtm.sold_volume}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{infringeTypes[dtm.infringe].substring(0,8)}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{dtm.reported_at.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{reportStatuses[dtm.status]}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{sellerInfo ? risks[sellerInfo.risk] : null}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-13">{dtm.content.substring(0,10)}</div>
                  </div>

                </Link>
                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="body-page-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>
      </div>
    </div>
  );
};
