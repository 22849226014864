import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LeftMenu } from "../../components/LeftMenu";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

import { platforms, platformOptions } from "../../config/codes";
import { categories, categoryOptions } from "../../config/codes";
import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { currencyUnits, currencyUnitOptions } from "../../config/codes";
import { reportMethods, reportMethodOptions } from "../../config/codes";
import { reportStatuses, reportStatusOptions } from "../../config/codes";

export const ReportList = ({ dataParam, currPage, onPlatformSubmit, onCategorySubmit, onInfringeSubmit, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 플랫폼
  const [platform, setPlatform] = useState('');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 카테고리
  const [category, setCategory] = useState('');
  const handleCategory = (e) => {
    let value = e.target.value;
    setCategory(value);
    onCategorySubmit(value);
  };

  // 침해유형  
  const [infringe, setInfringe] = useState('');
  const handleInfringe = (e) => {
    let value = e.target.value;
    setInfringe(value);
    onInfringeSubmit(value);
  };

  // 컨텐츠타입
  const typeOptions = { movie : "영상", music : "음악", webtoon : "웹툰", brand : "상표권" }
  
  // 신고 결과  
  const statusOptions = { forger : "위조판매자",	seller : "정상판매자"}

  // 세부검색
  const searchOptions = [
    { value: 'seller', label: '판매자' },
    { value: 'determin_name', label: '제목' },
    { value: 'content', label: '내용' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="report-list">
      <div className="div-2">
        <div className="bulletin-body" id="mainbody">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">위조품신고목록</div>
            </div>

            <div className="body-sub-title" />

            <div className="body-head">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-13">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  <select className="text-wrapper-14" style={{width:"95px", height:"26px", border:"none"}} id="platform" name="platform" value={platform} onChange={handlePlatform}>
                    { platformOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-13">카테고리</div>
                </div>

                <div className="dropdown-2">

                  <select className="text-wrapper-14" style={{width:"95px", height:"26px", border:"none"}} id="category" name="category" value={category} onChange={handleCategory}>
                    { categoryOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-13">침해유형</div>
                </div>

                <div className="element-wrapper">

                  <select className="text-wrapper-14" style={{width:"95px", height:"26px", border:"none"}} id="infringe" name="infringe" value={infringe} onChange={handleInfringe}>
                    { infringeTypeOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>
              </div>

              {/* <BodySearch className="design-component-instance-node" /> */}
              {pageInfo ?
                <BodySearch className="design-component-instance-node" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-15">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">플랫폼</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">카테고리</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-16">제품명</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">판매자</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">정품가격</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">위조품가격</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-15">침해유형</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">신고일</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">확정일</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">상태</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/reports/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-17">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-17">{platforms[prd.platform]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17">{categories[prd.category]}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-17">{prd.determin_name.substring(0,40)}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-17">{prd.seller}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-17">{parseFloat(prd.product_price).toFixed(2)}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-17">{parseFloat(prd.determin_price).toFixed(2)}</div>
                  </div>

                  <div className="body-row-data-7">
                    <div className="text-wrapper-17">{infringeTypes[prd.infringe]}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-17">{prd.reported_at ? prd.reported_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-17">{prd.confirmed_at ? prd.confirmed_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-17">{reportStatuses[prd.status]}</div>
                  </div>

                </Link>
                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="design-component-instance-node" /> */}
            {pageInfo ?
              <BodyPage className="design-component-instance-node" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>

        {/* <FooterPanel className="footer-panel-instance" /> */}
        <MainLogo className="design-component-instance-node-2" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="design-component-instance-node-2" />
      </div>
    </div>
  );
};
