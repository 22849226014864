import React, {useState, useEffect, useRef } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import moment from "moment"
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
import { DatePickerInput } from "../../components/DatePickerInput";
import DashboardApi from "../../api/dashboards";
import CodeApi from "../../api/codes";
import ProductApi from "../../api/products";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { reportStatuses, reportStatusOptions } from "../../config/codes";
import { risks, riskOptions } from "../../config/codes";

// 차트 패키지지
import { Bar, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {  Chart as ChartJS,  CategoryScale,  LinearScale,  BarElement,  Title,  Tooltip,  Legend, ArcElement, } from "chart.js";
ChartJS.register( CategoryScale,  LinearScale,  BarElement, Title,  Tooltip,  Legend, ArcElement );
const COLORSET = [
  "rgba(0, 128, 0, 0.4)",
  "rgba(255, 0, 0, 0.4)",
  "rgba(0, 0, 255, 0.4)",  
  "rgba(0, 123, 255, 0.4)",  
  "rgba(128, 0, 128, 0.4)",
  "rgba(128, 128, 128, 0.4)",
  "rgba(255, 165, 0, 0.4)",  
  "rgba(255, 192, 203, 0.4)",  
  "rgba(255, 255, 0, 0.4)",  
  "rgba(152, 251, 152, 0.4)",  
];
const BAROPTIONS = { 
  responsive: true, 
  plugins: { 
    legend: { position: "top", },
    datalabels: {
      align: 'end',
      anchor: 'end',
      formatter: function (value, context) {
        // return context.dataIndex + ': ' + Math.round(value*100) + '%';
        // return context.chart.data.labels[context.dataIndex];
        if (value === 0) return ""; 
        return value;
      },
    },
  }, 
};  
const PIEOPTIONS = { 
  responsive: true, 
  plugins: { 
    legend: { display: false, position: "right", },
    datalabels: {
      color: "#ff0000", 
      display: true,
      align: 'end',
      anchor: 'start',
      formatter: function (value, context) {
        const label = context.chart.data.labels[context.dataIndex];
        // const formattedVal = Intl.NumberFormat("en-US", {
        //   minimumFractionDigits: 1,
        // }).format(value);
        // return `${label}`;
        let sum = 0;
        let dataArr = context.chart.data.datasets[0].data;
        dataArr.map(data => {
            sum += data;
        });
        if (label) {
          let percentage = label.substring(0,5) + "\n" + value + "(" + (value*100/sum).toFixed(1)+"%)";
          return percentage;        
        }
        else{
          return value + "(" + (value*100/sum).toFixed(1)+"%)";
        }
      },
    },
    maintainAspectRatio: false,
  }
};

export const DashboardList = ({ dataParam, currPage, onPageSubmit, onMonthSubmit, onNationSubmit, onPlatformSubmit, onInfringeTypeSubmit, onProductNameSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 오늘 날짜
  const today = moment()
  const refBrand = useRef()

  // 요약차트
  const [summaryChartData, setSummaryChartData] = useState({})
  const refSummaryChartData = useRef(null);

  // 분석결과차트
  const [resultChartData, setResultChartData] = useState({})
  const refResultChartData = useRef(null);

  // 신규집계
  const refNewSumDuration = useRef();
  const refNewSumMonitoring = useRef();
  const refNewSumApply = useRef();
  const refNewSumConfirm = useRef();
  const refNewSumPassed = useRef();

  // 누적집계
  const refTotSumDuration = useRef();
  const refTotSumMonitoring = useRef();
  const refTotSumApply = useRef();
  const refTotSumConfirm = useRef();
  const refTotSumPassed = useRef();

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            setContinent(response.data.list.length > 0 ? response.data.list[0].value : '');
            setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            let value = response.data.list.length > 0 ? response.data.list[0].value : '';
            setNation(value);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', value, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(response.data.list.length > 0 ? response.data.list[0].value : '');
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }
  
  // 브랜드 코드 구하기기
  const PullBrandCode = () => {
    let formData = { include: '' }
    ProductApi.getBrandCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setBrand(value);
        setBrands(response.data.list);
        refBrand.current = value;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 브랜드
  const [brand, setBrand] = useState('');
  const [brands, setBrands] = useState([])
  const handleBrand = (e) => {
    let value = e.target.value;
    setBrand(value);
    handleProcessSubmit(process, value, nation, platform, duration);
  };

  // 대륙
  const [continent, setContinent] = useState('');
  const [continents, setContinents] = useState([])
  const handleContinent = (e) => {
    let value = e.target.value;
    setContinent(value);
    PullComCode('continent', value, 1);
  }

  // 국가
  const [nation, setNation] = useState('');
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', 2);
    } else {
      PullComCode('continent', value, 2);
    }
    handleProcessSubmit(process, brand, value, platform, duration);
  };

  // 플랫폼
  const [platform, setPlatform] = useState('');
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    handleProcessSubmit(process, brand, nation, value, duration);
  };

  // 기간(월)
  const durations = [
    { value: 'today', label: '오늘' },
    { value: 'week', label: '7일' },
    { value: 'month', label: '30일' },
    { value: 'year', label: '1년' },
  ];  
  const [duration, setDuration] = useState('today');
  const refDuration = useRef();
  const handleDuration = (e) => {
    let value = e.target.value;
    setDuration(value);
    refDuration.current = value;
    console.log('duration: ' + value);
    handleProcessSubmit(process, brand, nation, platform, value);
  };

  function getFromMoment(value) {
    let frMoment = moment();
    if (value === 'today') {
      frMoment = moment().subtract(23,'hours');
    }
    else if (value === 'week') {
      frMoment = moment().subtract(6,'days');
    }
    else if (value === 'month') {
      frMoment = moment().subtract(1,'months');
      frMoment = frMoment.add(1, 'days');
    }
    else if (value === 'year') {
      frMoment = moment().subtract(11,'months');
      frMoment = frMoment.add(1, 'days');
      frMoment = moment(frMoment.format("YYYY-MM") + '-01 00:00:00')  
    }
    return frMoment;
  }

  function durationDisplayFunc(frMoment) {
    return '(' + frMoment.format('YY-MM-DD') + '~' + moment().format('YY-MM-DD') + ')'; 
  }

  // 판별품 요약
  const [determined, setDetermined] = useState('nationsummary');
  const handleDeterminedSubmit = (determinedParam, brandParam, nationParam, platformParam, durationParam, processParam) => {

    // API 전달 인수
    let frDate = getFromMoment(durationParam).format("YYYY-MM-DD HH:mm:ss");
    const formData = {
      brand: brandParam,
      nation: nationParam,
      platform: platformParam,
      frDate: frDate,
      interval: durationParam
    }

    if (determinedParam === "nationsummary") {
      if (processParam === "proceed" || processParam === "perproduct" || processParam === "permonth") {
        // 국가별 집계
        DashboardApi.determinedNationSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(nationsDict[data.nation]);
                values.push(data.count);
              })
            }
  
            let dataset = [{
              label: '국가별 판별',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });
  
            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
      else if (processParam === "blocked") {
        // 국가별 집계
        DashboardApi.reportNationSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(nationsDict[data.nation]);
                values.push(data.count);
              })
            }
  
            let dataset = [{
              label: '국가별 판별',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });
  
            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
      else if (processParam === "malseller") {
        // 국가별 집계
        DashboardApi.malsellerNationSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(nationsDict[data.nation]);
                values.push(data.count);
              })
            }
  
            let dataset = [{
              label: '국가별 판별',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });
  
            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
    }
    else if (determinedParam === "platformsummary") {
      if (processParam === "proceed" || processParam === "perproduct" || processParam === "permonth") {
        // 침해 유형별 집계
        DashboardApi.determinedPlatformSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(platformsDict[data.platform]);
                values.push(data.count);
              })
            }

            let dataset = [{
              label: '플랫폼별현황',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });

            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
      else if (processParam === "blocked") {
        // 국가별 집계
        DashboardApi.reportPlatformSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(platformsDict[data.platform]);
                values.push(data.count);
              })
            }
  
            let dataset = [{
              label: '국가별 판별',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });
  
            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
      else if (processParam === "malseller") {
        // 국가별 집계
        DashboardApi.malsellerPlatformSummary(formData).then(response => {
          if(response.status === 200) {
            let datas = response.data.results;
            let labels = [];
            let values = [];
            if (response.data.results && response.data.results.length > 0) {
              response.data.results.map((data, index, total) => {
                labels.push(platformsDict[data.platform]);
                values.push(data.count);
              })
            }
  
            let dataset = [{
              label: '국가별 판별',
              data: values,         
              backgroundColor: COLORSET,   
            },];
            setSummaryChartData({
              labels,
              datasets: dataset
            });
  
            refSummaryChartData.current = {
              labels,
              datasets: dataset
            };
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        });
      }
    }
    else if (determinedParam === "infringesummary") {
      // 침해 유형별 집계
      DashboardApi.determinedInfringeSummary(formData).then(response => {
        if(response.status === 200) {
          let datas = response.data.results;
          let labels = [];
          let values = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              labels.push(infringeTypes[data.infringe]);
              values.push(data.count);
            })
          }

          let dataset = [{
            label: '침해유형별',
            data: values,         
            backgroundColor: COLORSET,   
          },];
          setSummaryChartData({
            labels,
            datasets: dataset
          });

          refSummaryChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    else if (determinedParam === "modelsummary") {
      // 침해 유형별 집계
      DashboardApi.determinedModelSummary(formData).then(response => {
        if(response.status === 200) {
          let datas = response.data.results;
          let labels = [];
          let values = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              labels.push(data.model);
              values.push(data.count);
            })
          }

          let dataset = [{
            label: '제품별',
            data: values,         
            backgroundColor: COLORSET,   
          },];
          setSummaryChartData({
            labels,
            datasets: dataset
          });

          refSummaryChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    setDetermined(determinedParam);
  }

  // 메인 프로세스
  const [process, setProcess] = useState('permonth');
  const handleProcessSubmit = (processParam, brandParam, nationParam, platformParam, durationParam) => {
    if(processParam === "permonth") {
      durationParam = 'year';
      setDuration(durationParam);
    }
      
    // API 전달 인수(formData)
    let frDate = getFromMoment(durationParam).format("YYYY-MM-DD HH:mm:ss");
    const formData = {
      brand: brandParam,
      nation: nationParam,
      platform: platformParam,
      frDate: frDate,
      interval: durationParam
    }
    // 화면 상단 신고요약 건수 API
    DashboardApi.reportTotalSummary(formData).then(response => {
      if(response.status === 200) {
        let data = response.data.results;
        // 요약 테이블 데이터 저장
        refNewSumDuration.current = data.new_date ? data.new_date : 0;
        refNewSumMonitoring.current = data.new_monitoring ? data.new_monitoring : 0;
        refNewSumApply.current = data.new_apply ? data.new_apply : 0;
        refNewSumConfirm.current = data.new_confirm ? data.new_confirm : 0;
        refNewSumPassed.current = data.new_passed ? data.new_passed : 0;

        refTotSumDuration.current = data.tot_date ? data.tot_date.substring(0,7) + '~' + today.format('YYYY-MM') : today.format('YYYY-MM') + '~' + today.format('YYYY-MM');
        refTotSumMonitoring.current = data.tot_monitoring ? data.tot_monitoring : 0;
        refTotSumApply.current = data.tot_apply ? data.tot_apply : 0;
        refTotSumConfirm.current = data.tot_confirm ? data.tot_confirm : 0;
        refTotSumPassed.current = data.tot_passed ? data.tot_passed : 0;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });

    if(processParam === "proceed") {
      // 국가별 판별품 요약 호출
      let determined = "nationsummary";
      setDetermined(determined);
      handleDeterminedSubmit(determined, brandParam, nationParam, platformParam, durationParam, processParam);

      // 위조품 판별 집계(분석결과)
      DashboardApi.proceedResult(formData).then(response => {
        if(response.status === 200) {
          const dataset = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              dataset.push({
                label: infringeTypes[data.infringe],
                data: data.data,
                backgroundColor: (index < 10) ? COLORSET[index] : COLORSET[0]
              })
            });
          }
          const labels = response.data.columns;

          setResultChartData({
            labels,
            datasets: dataset
          });
          refResultChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    else if(processParam === "blocked") {
      // 국가별 차단 실적 요약 호출
      let determined = "nationsummary";
      setDetermined(determined);
      handleDeterminedSubmit(determined, brandParam, nationParam, platformParam, durationParam, processParam);

      // 차단 실적 집계(분석결과)     
      DashboardApi.blockedResult(formData).then(response => {
        if(response.status === 200) {
          const dataset = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              dataset.push({
                label: reportStatuses[data.status],
                data: data.data,
                backgroundColor: (index < 10) ? COLORSET[index] : COLORSET[0]
              })
            });
          }
          const labels = response.data.columns;

          setResultChartData({
            labels,
            datasets: dataset
          });
          refResultChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    else if(processParam === "malseller") {
      // 국가별 요주의 판매상 요약 호출
      let determined = "nationsummary";
      setDetermined(determined);
      handleDeterminedSubmit(determined, brandParam, nationParam, platformParam, durationParam, processParam);

      // 요주의 판매자 집계(분석결과)     
      DashboardApi.malsellerResult(formData).then(response => {
        if(response.status === 200) {
          const dataset = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              dataset.push({
                label: risks[data.risk],
                data: data.data,
                backgroundColor: (index < 10) ? COLORSET[index] : COLORSET[0]
              })
            });
          }
          const labels = response.data.columns;

          setResultChartData({
            labels,
            datasets: dataset
          });
          refResultChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    else if(processParam === "perproduct") {

      // 국가별 제품 실적 요약 호출
      let determined = "nationsummary";
      setDetermined(determined);
      handleDeterminedSubmit(determined, brandParam, nationParam, platformParam, durationParam, processParam);

      // 제품별 위조품 판별 집계(분석결과)
      DashboardApi.determinedProductResult(formData).then(response => {
        if(response.status === 200) {
          const dataset = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              dataset.push({
                label: data.model,
                data: data.data,
                backgroundColor: (index < 10) ? COLORSET[index] : COLORSET[0]
              })
            });
          }
          const labels = response.data.columns;

          setResultChartData({
            labels,
            datasets: dataset
          });
          refResultChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    else if(processParam === "permonth") {
  
      // 국가별 판별품 요약 호출
      let determined = "nationsummary";
      setDetermined(determined);
      handleDeterminedSubmit(determined, brandParam, nationParam, platformParam, durationParam, processParam);

      // 월별 위조품 판별 집계(분석결과)
      DashboardApi.proceedResult(formData).then(response => {
        if(response.status === 200) {
          const dataset = [];
          if (response.data.results && response.data.results.length > 0) {
            response.data.results.map((data, index, total) => {
              dataset.push({
                label: infringeTypes[data.infringe],
                data: data.data,
                backgroundColor: (index < 10) ? COLORSET[index] : COLORSET[0]
              })
            });
          }
          const labels = response.data.columns;

          setResultChartData({
            labels,
            datasets: dataset
          });
          refResultChartData.current = {
            labels,
            datasets: dataset
          };
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      });
    }
    setProcess(processParam);
  } 

  const getLabelListOfDuration = (value) => {
    let durationArray = [];
    let toMoment = moment();
    let frMonent = getFromMoment(value);
    
    if (value === 'today') {
      const days = toMoment.diff(frMonent, 'hours');
      const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
      durationArray = dayRange.map((day) => {
        const date = moment(frMonent).add(day, 'hours');
        return date.format('YYYY-MM-DD HH');
      });
    }
    else if (value === 'week') {
      const days = toMoment.diff(frMonent, 'days');
      const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
      durationArray = dayRange.map((day) => {
        const date = moment(frMonent).add(day, 'days');
        return date.format('YYYY-MM-DD');
      });
    }
    else if (value === 'month') {
      const days = toMoment.diff(frMonent, 'days');
      const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
      durationArray = dayRange.map((day) => {
        const date = moment(frMonent).add(day, 'days');
        return date.format('YYYY-MM-DD');
      });
    }
    else if (value === 'year') {
      const days = toMoment.diff(frMonent, 'months');
      const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
      durationArray = dayRange.map((day) => {
        const date = moment(frMonent).add(day, 'months');
        return date.format('YYYY-MM');
      });
    }
    return durationArray;
  }  
  
  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 처음 한번 실행
  useEffect(() => {
    // 드롭다운 박스 데이터 가져오기
    PullBrandCode();
    PullComCode('continent', '', '0');

    // 브랜드 코드가 API로부터 가져와진 경우 스톱
    let timerId = setTimeout(function tick() {
      if (refBrand && refBrand.current && refBrand.current.length > 0) {
        handleProcessSubmit(process, brand, nation, platform, duration);
      }
      else
      {
        timerId = setTimeout(tick, 100); // (*)
        console.log('timer repreated!!!');
      }
    }, 100);
  }, []);

  return (
    <div className="dashboard-list">
      <div className="div-2">
        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>

        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-area">
            <div className="body-title">
              <div className="title-freme">
                {/* <div className="title">Carlyn</div> */}
                <select className="title" style={{width:"180px", height:"32px", border:"none"}} id="brand" name="brand" value={brand} onChange={handleBrand} ref={refBrand}>
                  { brands.map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>                  

              </div>
            </div>

            <div className="body-head">
              <div className="headitem">
                <div className="item">
                  <div className="item-2">
                    <div className="text-wrapper-7">신규 업데이트</div>
                  </div>

                  <div className="item-3">
                    <div className="text-wrapper-8">({refNewSumDuration.current})</div>
                  </div>
                </div>

                <div className="item-4">
                  <div className="item-5">
                    <div className="text-wrapper-9">모니터링</div>

                    <div className="element">{refNewSumMonitoring.current}</div>
                  </div>
                </div>

                <div className="item-4">
                  <div className="item-6">
                    <div className="text-wrapper-10">신고</div>

                    <div className="element-2">{refNewSumApply.current}</div>
                  </div>

                  <div className="item-6">
                    <div className="text-wrapper-10">차단</div>

                    <div className="element-2">{refNewSumConfirm.current}</div>
                  </div>

                  <div className="item-6">
                    <div className="text-wrapper-10">미통과</div>

                    <div className="element-2">{refNewSumPassed.current}</div>
                  </div>
                </div>
              </div>

              <div className="headitem">
                <div className="item">
                  <div className="item-2">
                    <div className="text-wrapper-7">누계</div>
                  </div>

                  <div className="item-3">
                    <p className="text-wrapper-8">({refTotSumDuration.current})</p>
                  </div>
                </div>

                <div className="item-4">
                  <div className="item-5">
                    <div className="text-wrapper-9">모니터링</div>

                    <div className="element">{refTotSumMonitoring.current}</div>
                  </div>
                </div>

                <div className="item-4">
                  <div className="item-6">
                    <div className="text-wrapper-10">신고</div>

                    <div className="element-2">{refTotSumApply.current}</div>
                  </div>

                  <div className="item-6">
                    <div className="text-wrapper-10">차단</div>

                    <div className="element-2">{refTotSumConfirm.current}</div>
                  </div>

                  <div className="item-6">
                    <div className="text-wrapper-10">미통과</div>

                    <div className="element-2">{refTotSumPassed.current}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-summary">
              <div className="div-wrapper-2">
                <div className="text-wrapper-11">요약</div>
              </div>

              <div className="summary-filter">
                <div className="filter">
                  <div className="filter-2" style={{cursor: 'pointer', backgroundColor: (determined === 'nationsummary') ? '#163E64' : null}} onClick={()=>{handleDeterminedSubmit('nationsummary', brand, nation, platform, duration, process)}}>
                    <div className="text-wrapper-12" style={{color: (determined === 'nationsummary') ? '#ffffff' : null}}>국가</div>
                  </div>

                  <div className="div-wrapper-3" style={{cursor: 'pointer', backgroundColor: (determined === 'platformsummary') ? '#163E64' : null}} onClick={()=>{handleDeterminedSubmit('platformsummary', brand, nation, platform, duration, process)}}>
                    <div className="text-wrapper-12" style={{color: (determined === 'platformsummary') ? '#ffffff' : null}}>플랫폼</div>
                  </div>

                  <div className="div-wrapper-3" style={{display: (process === 'blocked' || process === 'malseller') ? 'none' : null, cursor: 'pointer', backgroundColor: (determined === 'infringesummary' || determined === 'modelsummary') ? '#163E64' : null}} onClick={()=>{handleDeterminedSubmit(process === 'perproduct' ? 'modelsummary' : 'infringesummary', brand, nation, platform, duration, process)}}>
                    <div className="text-wrapper-12" style={{color: (determined === 'infringesummary' || determined === 'modelsummary') ? '#ffffff' : null}}>{(process === 'perproduct') ? '제품별' : '침해유형'}</div>
                  </div>
                </div>
              </div>

              <div className="summary-main" style={{ width: "640px", height: "320px", margin: "0 auto" }}>
                {/* <div className="chart-area">
                </div> */}
                {
                refSummaryChartData.current ?
                <Doughnut options={PIEOPTIONS} data={refSummaryChartData.current ? refSummaryChartData.current : null} plugins={[ChartDataLabels]}/>
                :
                null
                } 

              </div>
            </div>

            <div className="body-detail">
              <div className="div-wrapper-2">
                <div className="text-wrapper-11">분석결과</div>
              </div>

              <div className="detail-filter">
                <div className="continent">
                  <div className="div-wrapper-4">
                    <div className="text-wrapper-13">대륙</div>
                  </div>

                  <div className="continent-2">
                    {/* <div className="text-wrapper-12">아시아</div> */}
                    <select className="text-wrapper-12" style={{width:"55px", height:"25px", border:"none"}} id="continent" name="continent" value={continent} onChange={(e) => handleContinent(e)}>
                      { continents.map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>                  

                  </div>
                </div>

                <div className="nation">
                  <div className="div-wrapper-4">
                    <div className="text-wrapper-13">국가</div>
                  </div>

                  <div className="div-wrapper-3">
                    {/* <div className="text-wrapper-12">중국</div> */}
                    <select className="text-wrapper-12" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                      { nations.map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>                  

                  </div>
                </div>

                <div className="platform">
                  <div className="platform-2">
                    <div className="text-wrapper-14">플랫폼</div>
                  </div>

                  <div className="div-wrapper-3">
                    {/* <div className="text-wrapper-12">저작권</div> */}
                    <select className="text-wrapper-12" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                      { platforms.map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>                  

                  </div>
                </div>

                <div className="filter-3">
                  <div className="filter-2" style={{cursor: 'pointer', backgroundColor: (process === 'proceed') ? '#163E64' : null}} onClick={()=>{handleProcessSubmit('proceed', brand, nation, platform, duration)}}>
                    <div className="text-wrapper-12" style={{color: (process === 'proceed') ? '#ffffff' : null}}>모니터링</div>
                  </div>

                  <div className="div-wrapper-3" style={{cursor: 'pointer', backgroundColor: (process === 'blocked') ? '#163E64' : null }} onClick={()=>{handleProcessSubmit('blocked', brand, nation, platform, duration)}}>
                    <div className="text-wrapper-12" style={{color: (process === 'blocked') ? '#ffffff' : null}}>차단실적</div>
                  </div>

                  <div className="div-wrapper-3" style={{cursor: 'pointer', backgroundColor: (process === 'malseller') ? '#163E64' : null}} onClick={()=>{handleProcessSubmit('malseller', brand, nation, platform, duration)}}>
                    <div className="text-wrapper-12" style={{color: (process === 'malseller') ? '#ffffff' : null}}>요주판매</div>
                  </div>

                  <div className="div-wrapper-3" style={{cursor: 'pointer', backgroundColor: (process === 'perproduct') ? '#163E64' : null}} onClick={()=>{handleProcessSubmit('perproduct', brand, nation, platform, duration)}}>
                    <div className="text-wrapper-12" style={{color: (process === 'perproduct') ? '#ffffff' : null}}>제품별</div>
                  </div>

                  <div className="div-wrapper-3" style={{cursor: 'pointer', backgroundColor: (process === 'permonth') ? '#163E64' : null}} onClick={()=>{handleProcessSubmit('permonth', brand, nation, platform, duration)}}>
                    <div className="text-wrapper-12" style={{color: (process === 'permonth') ? '#ffffff' : null}}>월별</div>
                  </div>
                </div>

                <div className="duration">
                  <div className="div-wrapper-4">
                    <div className="text-wrapper-13">기간</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-15">오늘</div>
                  </div> */}
                  <select className="div-wrapper-3" style={{width:"60px", height:"25px"}} id="duration" name="duration" value={duration} onChange={(e) => handleDuration(e)} ref={refDuration}>
                    { durations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                      


                  <div className="duration-2">
                    <div className="text-wrapper-15">{durationDisplayFunc(getFromMoment(duration))}</div>
                  </div>
                </div>
              </div>

              <div className="detail-main" style={{ width: "760px", height: "380px", margin: "0 auto" }}>
              {
                refResultChartData.current ?
                <Bar options={BAROPTIONS} data={refResultChartData.current ? refResultChartData.current : null} plugins={[ChartDataLabels]}/>
                :
                null
              } 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
