import axios from "./index";

class CodeApi {
  // 코드, 코드명
  static comCode = (formData) => {
    const response = axios.post(`/codes/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };
  
}
export default CodeApi;