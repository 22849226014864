import * as monitoringApi from "../api/monitorings";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_MONITORINGLIST = "GET_MONITORINGLIST";
const GET_MONITORINGLIST_SUCCESS = "GET_MONITORINGLIST_SUCCESS";
const GET_MONITORINGLIST_ERROR = "GET_MONITORINGLIST_ERROR";

// 상품 항목 조회하기
const GET_MONITORINGDETAIL = "GET_MONITORINGDETAIL";
const GET_MONITORINGDETAIL_SUCCESS = "GET_MONITORINGDETAIL_SUCCESS";
const GET_MONITORINGDETAIL_ERROR = "GET_MONITORINGDETAIL_ERROR";

const CLEAR_MONITORINGTLIST = "CLEAR_MONITORINGTLIST";

export const getMonitoringList = (page, month, nation, platform, infringeType, productName) => ({
  type: GET_MONITORINGLIST,
  payload: { page: page, month: month, nation: nation, platform: platform, infringeType: infringeType, productName: productName},
});

export const getMonitoringDetail = (access, id) => ({
  type: GET_MONITORINGDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearMonitoringList = () => ({ type: CLEAR_MONITORINGLIST });

const getMonitoringListSaga = createPromiseSagaList(
  GET_MONITORINGLIST,
  monitoringApi.getMonitoringList
);

const getMonitoringDetailSaga = createPromiseSagaDetail(
  GET_MONITORINGDETAIL,
  monitoringApi.getMonitoringDetail
);

// 사가들을 합치기
export function* monitoringSaga() {
  yield takeEvery(GET_MONITORINGLIST, getMonitoringListSaga);
  yield takeEvery(GET_MONITORINGDETAIL, getMonitoringDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  monitoringlist: reducerUtils.initial(),
  monitoringdetail: reducerUtils.initial(),
};

export default function monitoringReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MONITORINGLIST:
    case GET_MONITORINGLIST_SUCCESS:
    case GET_MONITORINGLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_MONITORINGLIST,
        "monitoringlist",
        true
      );
      return reducerList(state, action);

    case GET_MONITORINGDETAIL:
    case GET_MONITORINGDETAIL_SUCCESS:
    case GET_MONITORINGDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_MONITORINGDETAIL,
        "monitoringdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_MONITORINGTLIST:
      return {
        ...state,
        monitoringlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
