import axios from "./index";

export const getDashboardList = async (params) => {
  const response = await axios.get("/monitorings/", { params: params });
  return response.data;
};
export const getDashboardDetail = async (formData) => {
  const response = await axios.get(`/dashboards/${formData.id}/`);
  return response.data;
};

class DashboardApi {
  // 차단실적 토탈 집계
  static reportTotalSummary = (formData) => {
    const response = axios.post(`/report_total_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };
  
  // 판별품 국가별 집계
  static determinedNationSummary = (formData) => {
    const response = axios.post(`/determined_nation_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 판별품 플랫폼별 집계
  static determinedPlatformSummary = (formData) => {
    const response = axios.post(`/determined_platform_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 판별품 침해유형별 집계
  static determinedInfringeSummary = (formData) => {
    const response = axios.post(`/determined_infringe_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 판별품 모델별 집계
  static determinedModelSummary = (formData) => {
    const response = axios.post(`/determined_model_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  //차단신고 국가별 집계
  static reportNationSummary = (formData) => {
    const response = axios.post(`/report_nation_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 차단신고 플랫폼별 집계
  static reportPlatformSummary = (formData) => {
    const response = axios.post(`/report_platform_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 요주의판매상 국가별 집계
  static malsellerNationSummary = (formData) => {
    const response = axios.post(`/malseller_nation_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 요주의판매상 플랫폼별 집계
  static malsellerPlatformSummary = (formData) => {
    const response = axios.post(`/malseller_platform_summary/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 판별품 날짜별 건수 집계(분석결과)  
  static proceedResult = (formData) => {
    const response = axios.post(`/proceed_result/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 차단신고 날짜별 집계(분석결과)  
  static blockedResult = (formData) => {
    const response = axios.post(`/blocked_result/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 요주의판매자 날짜별 집계(분석결과)  
  static malsellerResult = (formData) => {
    const response = axios.post(`/malseller_result/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 판별품 제품별 날짜별 건수 집계(분석결과)  
  static determinedProductResult = (formData) => {
    const response = axios.post(`/determined_product_result/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };
}
export default DashboardApi;