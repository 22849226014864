import React, { useState, useRef, useEffect } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import "./style.css";
import { Link, useNavigate } from 'react-router-dom';
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";
import { Input } from "../../components/Input";

export const Password = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [error, setError] = useState("");
  const [loginId, setLoginId] = useState("");

  const refLoginId = useRef();
  const navigate = useNavigate();

  const onChange = e => {
    const { value, name } = e.target;
    setLoginId(value);
  };

  // validate Input Columns
  const validateInputs = () => {
    if (refLoginId.length <= 0) {
      const result = alert("이메일 아이디를 입력해 주세요!");
      refLoginId.current.focus();
      return false;
    }
    return true;
  }

  const handlePasswordSubmit = e => {
    e.preventDefault();
    if (validateInputs() === false) {
      return;
    } 
    let formData = new FormData();
    formData.append("emailid", loginId);
    AuthApi.Password(formData).then(response => {
      if(response.status == 200) {
        alert(`${loginId}로 임시 비밀번호가 전송되었습니다.`)
        navigate('/login');
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  return (
    <div className="password">
      <div className="div-2">
        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-container">
            <div className="login-area">
              <div className="login-text">
                <div className="login-title">
                  <div className="text-wrapper-6">비밀번호 찾기</div>
                </div>

                <div className="login-sub-title">
                  <p className="p">
                    Trust Data 에 가입하신 이메일을 통해 임시 비밀번호를 부여
                    받을 수 있습니다
                  </p>
                </div>
              </div>

              <div className="form-login">
                <div className="login-id-frame" style={{border:"none"}}>
                  {/* <div className="login-id">이메일아이디</div> */}
                  <Input className="login-id" inputType='text' name='loginId' value={loginId} onChange={onChange} ref={refLoginId} text="이메일아이디"/>

                </div>

                <div className="login-btn-frame" style={{cursor:"pointer", backgroundColor:"#163E64" }} onClick={(e) => handlePasswordSubmit(e)}>
                  <div className="login-btn">비밀번호 찾기</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>
      </div>
    </div>
  );
};
