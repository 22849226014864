import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AssessListPage } from "./screens/AssessListPage/AssessListPage";
import { AssessDetailPage } from "./screens/AssessDetailPage/AssessDetailPage";
import { BulletinListPage } from "./screens/BulletinListPage";
import { BulletinDetailPage } from "./screens/BulletinDetailPage";
import { CacheDepositMutong } from "./screens/CacheDepositMutong";
import { CacheDeposit } from "./screens/CacheDeposit";
import { CollectListPage } from "./screens/CollectListPage/CollectListPage";
import { CollectDetailPage } from "./screens/CollectDetailPage/CollectDetailPage";
import { ContentListPage } from "./screens/ContentListPage/ContentListPage";
import { ContentDetailPage } from "./screens/ContentDetailPage/ContentDetailPage";
import { DashboardListPage } from "./screens/DashboardListPage/DashboardListPage";
import { DeterminListPage } from "./screens/DeterminListPage/DeterminListPage";
import { DeterminDetailPage } from "./screens/DeterminDetailPage/DeterminDetailPage";
import { ForgeryListPage } from "./screens/ForgeryListPage/ForgeryListPage";
import { ForgeryDetailPage } from "./screens/ForgeryDetailPage/ForgeryDetailPage";
import { ForgeryReportPage } from "./screens/ForgeryReportPage/ForgeryReportPage";
import { FaqListPage } from "./screens/FaqListPage";
import { FaqDetailPage } from "./screens/FaqDetailPage";
import { InquestListPage } from "./screens/InquestListPage";
import { InquiryListPage } from "./screens/InquiryListPage";
import { InquiryCreatePage } from "./screens/InquiryCreatePage/InquiryCreatePage";
import { InquiryDetailPage } from "./screens/InquiryDetailPage";
import { InvestgateListPage } from "./screens/InvestgateListPage/InvestgateListPage";
import { InvestgateDetailPage } from "./screens/InvestgateDetailPage/InvestgateDetailPage";
import { IprListPage } from "./screens/IprListPage/IprListPage";
import { IprDetailPage } from "./screens/IprDetailPage/IprDetailPage";
import { LawsuitListPage } from "./screens/LawsuitListPage/LawsuitListPage";
import { LawsuitDetailPage } from "./screens/LawsuitDetailPage/LawsuitDetailPage";
import { LoginPage } from "./screens/LoginPage";
import { PasswordPage } from "./screens/PasswordPage";
import { LoginRedirectNaverPage } from "./screens/LoginPage";
import { MonitoringListPage } from "./screens/MonitoringListPage/MonitoringListPage";
import { MonitoringDetailPage } from "./screens/MonitoringDetailPage/MonitoringDetailPage";
import { MyCacheList } from "./screens/MyCacheList";
import { MyCouponList } from "./screens/MyCouponList";
import { MyInfoUpdate } from "./screens/MyInfoUpdate";
import { MyInviteLink } from "./screens/MyInviteLink";
import { MyPurchaseList } from "./screens/MyPurchaseList";
import { MyPurchaseStat } from "./screens/MyPurchaseStat";
import { MyWishList } from "./screens/MyWishList";
import { PersonalPrivacy } from "./screens/PersonalPrivacyPage";
import { ProductListPage } from "./screens/ProductListPage/ProductListPage";
import { ProductDetailPage } from "./screens/ProductDetailPage";
import { PurchaseCard } from "./screens/PurchaseCard";
import { PurchaseMutong } from "./screens/PurchaseMutong";
import { PurchaseVirtual } from "./screens/PurchaseVirtual";
import { RefundCancel } from "./screens/RefundCancel";
import { ReportListPage } from "./screens/ReportListPage/ReportListPage";
import { ReportDetailPage } from "./screens/ReportDetailPage/ReportDetailPage";
import { MalsellerListPage } from "./screens/MalsellerListPage/MalsellerListPage";
import { MalsellerDetailPage } from "./screens/MalsellerDetailPage/MalsellerDetailPage";
import { SellerListPage } from "./screens/SellerListPage/SellerListPage";
import { SignupSelectPage } from "./screens/SignupSelectPage";
import { SignupAgreePage } from "./screens/SignupAgreePage/SignupAgreePage";
import { SignupRegistryPage } from "./screens/SignupRegistryPage";
import { SubscribeApplyListPage } from "./screens/SubscribeApplyListPage/SubscribeApplyListPage";
import { SubscribingListPage } from "./screens/SubscribingListPage/SubscribingListPage";
import { TermsOfUse } from "./screens/TermsOfUsePage";

const router = createBrowserRouter(
[
  {
    path: "/assesses",
    element: <AssessListPage />,
  },
  {
    path: "/assesslist",
    element: <AssessListPage />,
  },
  {
    path: "/assesses/:id",
    element: <AssessDetailPage />,
  },
  {
    path: "/investgates",
    element: <InvestgateListPage />,
  },
  {
    path: "/investgatelist",
    element: <InvestgateListPage />,
  },
  {
    path: "/investgates/:id",
    element: <InvestgateDetailPage />,
  },
  {
    path: "/dashboards",
    element: <DashboardListPage />,
  },
  {
    path: "/monitorings",
    element: <MonitoringListPage />,
  },
  {
    path: "/monitoringlist",
    element: <MonitoringListPage />,
  },
  {
    path: "/monitorings/:id",
    element: <MonitoringDetailPage />,
  },

  {
    path: "/*",
    element: <InquestListPage />,
  },
  {
    path: "/contents",
    element: <ContentListPage />,
  },
  {
    path: "/contents/:id",
    element: <ContentDetailPage />,
  },
  {
    path: "/subscribe",
    element: <SubscribingListPage />,
  },
  {
    path: "/subscribings",
    element: <SubscribingListPage />,
  },
  {
    path: "/subscribeapplys",
    element: <SubscribeApplyListPage />,
  },
  {
    path: "/collects",
    element: <CollectListPage />,
  },
  {
    path: "/collectdetail/:_id",
    element: <CollectDetailPage />,
  },
  {
    path: "/determins",
    element: <DeterminListPage />,
  },
  {
    path: "/determinlist",
    element: <DeterminListPage />,
  },
  {
    path: "/determindetail/:_id",
    element: <DeterminDetailPage />,
  },
  {
    path: "/myinfoupdate",
    element: <MyInfoUpdate />,
  },
  {
    path: "/inquests",
    element: <InquestListPage />,
  },
  {
    path: "/inquirylist",
    element: <InquiryListPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/password",
    element: <PasswordPage />,
  },
  {
    path: "/signupagree",
    element: <SignupAgreePage />,
  },
  {
    path: "/signupregistry",
    element: <SignupRegistryPage />,
  },
  {
    path: "/redirectnaver",
    element: <LoginRedirectNaverPage />,
  },
  {
    path: "/myinvitelink",
    element: <MyInviteLink />,
  },
  {
    path: "/mypurchaselist",
    element: <MyPurchaseList />,
  },
  {
    path: "/mypurchasestat",
    element: <MyPurchaseStat />,
  },
  {
    path: "/mycachelist",
    element: <MyCacheList />,
  },
  {
    path: "/mywishlist",
    element: <MyWishList />,
  },
  {
    path: "/mycouponlist",
    element: <MyCouponList />,
  },
  {
    path: "/bulletinlist",
    element: <BulletinListPage />,
  },
  {
    path: "/bulletindetail/:id",
    element: <BulletinDetailPage />,
  },
  {
    path: "/faqlist",
    element: <FaqListPage />,
  },
  {
    path: "/faqdetail/:id",
    element: <FaqDetailPage />,
  },
  {
    path: "/purchasevirtual",
    element: <PurchaseVirtual />,
  },
  {
    path: "/purchasecard",
    element: <PurchaseCard />,
  },
  {
    path: "/inquirycreate",
    element: <InquiryCreatePage />,
  },
  {
    path: "/inquirydetail/:id",
    element: <InquiryDetailPage />,
  },
  {
    path: "/termsofuse",
    element: <TermsOfUse />,
  },
  {
    path: "/personalprivacy",
    element: <PersonalPrivacy />,
  },
  {
    path: "/refundcancel",
    element: <RefundCancel />,
  },
  {
    path: "/purchasemutong",
    element: <PurchaseMutong />,
  },
  {
    path: "/cachedepositmutong",
    element: <CacheDepositMutong />,
  },
  {
    path: "/cachedepositvirtual",
    element: <CacheDeposit />,
  },
  {
    path: "/malsellers",
    element: <MalsellerListPage />,
  },
  {
    path: "/malsellerdetail",
    element: <MalsellerDetailPage />,
  },
  {
    path: "/sellers",
    element: <SellerListPage />,
  },
  {
    path: "/sellerlist",
    element: <SellerListPage />,
  },
  {
    path: "/reports",
    element: <ReportListPage />,
  },
  {
    path: "/reportlist",
    element: <ReportListPage />,
  },
  {
    path: "/reports/:id",
    element: <ReportDetailPage />,
  },
  {
    path: "/forgeryreports",
    element: <ReportListPage />,
  },
  // 브랜드 관리
  {
    // 지적재산권관리
    path: "/brands",
    element: <IprListPage />,
  },
  {
    // 지적재산권관리
    path: "/iprlist",
    element: <IprListPage />,
  },
  {
    // 지적재산권관리-상세정보
    path: "/iprdetail/:id",
    element: <IprDetailPage />,
  },
  {
    // 정품정보관리
    path: "/productlist",
    element: <ProductListPage />,
  },
  {
    // 정품정보관리-상세정보
    path: "/productdetail/:id",
    element: <ProductDetailPage />,
  },
  {
    // 가품정보관리
    path: "/forgeries",
    element: <ForgeryListPage />,
  },
  {
    // 가품정보관리
    path: "/forgerylist",
    element: <ForgeryListPage />,
  },
  {
    // 가품정보관리-상세정보
    path: "/forgerydetail/:id",
    element: <ForgeryDetailPage />,
  },
  {
    // 위조품 신고
    path: "/forgeryreport/:id",
    element: <ForgeryReportPage />,
  },
  {
    // 무료소송
    path: "/lawsuits",
    element: <LawsuitListPage />,
  },
  {
    // 무료소송
    path: "/lawsuitlist",
    element: <LawsuitListPage />,
  },
  {
    // 무료소송-상세정보
    path: "/lawsuitdetail/:id",
    element: <LawsuitDetailPage />,
  },
  // 대시보드

]);

// const cors = require('cors');
// const corsOptions = {
// 	origin: 'https://argus1365.co.kr',
// };
// router.use(cors(corsOptions));


export const App = () => {
  return <RouterProvider router={router} />;
};
