import axios from "./index";

export const getInquestList = async (params) => {
  const response = await axios.get("/inquests/", { params: params });
  return response.data;
};
export const getInquestDetail = async (formData) => {
  const response = await axios.get(`/inquests/${formData.id}/`);
  return response.data;
};

class InquestApi {
  // 판별품 데이터 생성
  static insertInquest = (formData) => {
    const response = axios.post(`/inquests/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response;
  };

  // 위조품 데이터 재거
  static deleteInquest = (formData) => {
    let id = formData.get('id');
    const response = axios.delete(`/inquests/${id}/`);
    return response;
  };  
  
}
export default InquestApi;