var PROTOCOL = "https"
var BACKEND_DOMAIN = "data.argus1365.co.kr"

// Backend Base Url : should comment for release mode
// var PROTOCOL = "http"
// var BACKEND_DOMAIN = "127.0.0.1:8000"

export const BACKEND_SERVER_BASEURL = `${PROTOCOL}://${BACKEND_DOMAIN}`;


// Elasticsearch
let ELASTIC_DOMAIN = "data.argus1365.co.kr"
export const ELASTIC_SERVER_BASEURL = `https://${ELASTIC_DOMAIN}:9200`;   // blocked:mixed-content 문제


// 공지사항,자유게시판 페이지당 항목 갯수
export const BODY_COUNT_PER_PAGE = 20;

