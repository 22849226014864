import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMonitoringDetail } from "../modules/monitorings.js";
import { MonitoringDetail } from "../components/MonitoringDetail/MonitoringDetail.jsx";
import { useAuth } from "../contexts/auth/auth.context.js";

export const MonitoringDetailContainer = ( { id }) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const { data, loading, error } = useSelector(
    (state) => state.monitoringReducer.monitoringdetail[id]
    ) || {
      loading: false,
      data: null,
      error: null,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    const access = (user ? user.access : '')
    dispatch(getMonitoringDetail(access, id));
  }, [dispatch, id]);

  if (loading) return <div>로딩중...</div>;
  if (error) {
    return (
      <MonitoringDetail monitoring={error.data}/>
    );
  }
  else if (data) {
    return (
      <MonitoringDetail law={data} />
    );
  }
}

