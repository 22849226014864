import * as investgateApi from "../api/investgates";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_INVESTGATELIST = "GET_INVESTGATELIST";
const GET_INVESTGATELIST_SUCCESS = "GET_INVESTGATELIST_SUCCESS";
const GET_INVESTGATELIST_ERROR = "GET_INVESTGATELIST_ERROR";

// 상품 항목 조회하기
const GET_INVESTGATEDETAIL = "GET_INVESTGATEDETAIL";
const GET_INVESTGATEDETAIL_SUCCESS = "GET_INVESTGATEDETAIL_SUCCESS";
const GET_INVESTGATEDETAIL_ERROR = "GET_INVESTGATEDETAIL_ERROR";

const CLEAR_INVESTGATETLIST = "CLEAR_INVESTGATETLIST";

export const getInvestgateList = (platform, progress, todate, searchType, search, pageNumber) => ({
  type: GET_INVESTGATELIST,
  payload: { platform: platform, progress: progress, todate: todate, searchType: searchType, search: search, page: pageNumber },
});

export const getInvestgateDetail = (access, id) => ({
  type: GET_INVESTGATEDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearInvestgateList = () => ({ type: CLEAR_INVESTGATELIST });

const getInvestgateListSaga = createPromiseSagaList(
  GET_INVESTGATELIST,
  investgateApi.getInvestgateList
);

const getInvestgateDetailSaga = createPromiseSagaDetail(
  GET_INVESTGATEDETAIL,
  investgateApi.getInvestgateDetail
);

// 사가들을 합치기
export function* investgateSaga() {
  yield takeEvery(GET_INVESTGATELIST, getInvestgateListSaga);
  yield takeEvery(GET_INVESTGATEDETAIL, getInvestgateDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  investgatelist: reducerUtils.initial(),
  investgatedetail: reducerUtils.initial(),
};

export default function investgateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVESTGATELIST:
    case GET_INVESTGATELIST_SUCCESS:
    case GET_INVESTGATELIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_INVESTGATELIST,
        "investgatelist",
        true
      );
      return reducerList(state, action);

    case GET_INVESTGATEDETAIL:
    case GET_INVESTGATEDETAIL_SUCCESS:
    case GET_INVESTGATEDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_INVESTGATEDETAIL,
        "investgatedetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_INVESTGATETLIST:
      return {
        ...state,
        investgatelist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
