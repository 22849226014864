import axios from "./index";

class MalsellerApi {
  static getMalsellerList = async (params) => {
    const response = await axios.get("/malcious_sellers/", { params: params });
    return response.data;
  };

  static getMalsellerDetail = async (params) => {
    const response = await axios.get("/malcious_seller_detail/", { params: params });
    return response.data;
  };

}

export default MalsellerApi;
