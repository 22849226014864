import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const SellerList = ({ dataParam, currPage, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 컨텐츠타입
  const typeOptions = { movie : "영상", music : "음악", webtoon : "웹툰", brand : "상표권" }
  
  // 침해 유형  
  const infringeTypeOptions = { unallowed : "무단사용", illegalsite : "불법사이트" }

  // 신고 결과  
  const statusOptions = { forger : "위조판매자",	seller : "정상판매자"}

  // 세부검색
  const searchOptions = [
    { value: 'shop', label: '쇼핑몰' },
    { value: 'seller_id', label: '아이디' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="seller-list">
      <div className="div-2">
        {/* <FooterPanel className="footer-panel-instance" /> */}
        <div className="bulletin-body">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">위조판매자</div>
            </div>

            <div className="body-sub-title" />

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-14">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">판매자</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">판매자홈</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-14">회사명</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-14">주쇼핑몰</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-14">상품갯수</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-14">판매량</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-14">상태</div>
                  </div>
                </div>


                {datas ? datas.map((dtm, index, total) => (
                <Link className="record-frame" to={`/sellers/${dtm.id}`} key={dtm.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-16">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-17">{dtm.seller_id}({dtm.seller_name})</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17">{dtm.home}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-18">{dtm.company}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-16">{dtm.shop}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-16">0</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-16">0</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-19">{statusOptions[dtm.status]}</div>
                  </div>

                </Link>
                )) : <></>}

                
              </div>
            </div>

            {/* <BodyPage className="body-page-instance" />
            <BodySearch className="body-search-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}
            {pageInfo ?
              <BodySearch className="body-search-instance" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
            :<></>}


          </div>
        </div>

        <LeftMenu className="left-menu-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
