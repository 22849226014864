import axios from "./index";

class ReportApi {
  static getReportList = async (params) => {
    const response = await axios.get("/reports/", { params: params });
    return response.data;
  };

  static getReportDetail = async (formData) => {
    const response = await axios.get(`/reports/${formData.id}/`);
    return response.data;
  };

  static postReportCreate = (formData) => {
    return axios.post(`reportcreate/`, formData);
  };

  // 판별품 데이터 생성
  static InsertReport = (formData) => {
    return axios.post(`/reports/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  static UpdateReport = (id, formData) => {
    let data = `/reports/${id}/`
    return axios.post(`/reports/${id}/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

}

export default ReportApi;
