import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MalsellerList } from "../components/MalsellerList";
import { getMalsellerList } from "../modules/malsellers";
import { useAuth } from "../contexts/auth/auth.context";

export const MalsellerListContainer = ({nationParam = '', platformParam = '', riskParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ risk, setRisk ] = useState(riskParam);
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.malsellerReducer.malsellerlist);
  const dispatch = useDispatch();

  const onNationSubmit = (nationParam) => {
    setPage(1);
    setNation(nationParam);
  }

  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  const onRiskSubmit = (riskParam) => {
    setPage(1);
    setRisk(riskParam);
  }

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchParam) => {
    setPage(1);
    setSearch(searchParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getMalsellerList(nation, platform, risk, search, page));
  }, [dispatch, nation, platform, risk, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <MalsellerList 
    dataParam={data}
    currPage = {pageParam}
    onPageSubmit={onPageSubmit} 
    onNationSubmit={onNationSubmit} 
    onPlatformSubmit={onPlatformSubmit} 
    onRiskSubmit={onRiskSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

