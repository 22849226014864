import React, { useEffect } from "react";
import { useParams } from "react-router";
import { IprDetailContainer } from "../../containers/IprDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";

export const IprDetailPage = () => {
  const { id } = useParams();  

  return (
    <IprDetailContainer id={parseInt(id)} />
  );
}
