import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { getCategoryTree } from "../../modules/categorys";
// import menuData from './data.json'
import LeftMenuItem from './LeftMenuItem'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";

export const LeftMenu = ({ 
  className, 
  divClassName,
  divClassNameOverride,
  divClassName1,
  buttonClassName,
  divClassName2,
  buttonClassNameOverride,
  divClassName3,
  symbolWrapperClassName,
  category = 'all' }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const [ search, setSearch ] = useState("");
  const { data, loading, error } = useSelector(
    (state) => state.categoryReducer.categorytree
  );
  const dispatch = useDispatch();
 
  const nest = (menuData, code = "ROOT", parent_code = 'parent_code') =>
    menuData.filter(item => item[parent_code] === code)
      .map(item => ({ ...item, childrens: nest(menuData, item.code) }));

  useEffect(() => {
    // 메인 카테고리(ex:NS,IG등)의 경우에만 Request
    dispatch(getCategoryTree(category));
  }, [dispatch]); // , category
 
  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  // 상단 카테고리 메뉴를 클릭했을때 서버로부터 데이터를 다시 가져옴
  if (category && category.length > 0 && category != search) {
    setSearch(category);
  }
  // category가 '' 또는 'all'인 경우 'ROOT'를 그외엔 category 값으로 nest호출
  const tree = data ? nest(data, ((category && category !== 'all') ? category : 'ROOT')) : [];

  return (
    <div className={`left-menu ${className}`}>
      <div className="left-menu-head">
      <Link className={`text-wrapper ${divClassName}`} to={`/`} >Home</Link>
      <Link className={`div ${divClassNameOverride}`} to={`/`} ><FontAwesomeIcon icon={faHome} /></Link>

        {/* <div className="text-wrapper-6">Home&nbsp;&nbsp;<FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> */}
      </div>

      <div className="left-menu-list">

        {/* <div className="left-menu-item">
          <div className={`text-wrapper-2 ${divClassName1}`}>크라우드펀딩</div>

          <button className={`button ${buttonClassName}`}>
            <div className="symbol"></div>
          </button>
        </div> */}

        {tree.map((item, index) =>
          <LeftMenuItem item={item} key={index} />
        )}      

      </div>

      
    </div>
  );
};
