import React, {useState, useEffect } from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import CodeApi from "../../api/codes";
import ProductApi from "../../api/products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";

export const ForgeryList = ({ dataParam, brandParam, nationParam, platformParam, infringeParam, searchTypeParam, searchTextParam, sortParam, pageParam, onPageSubmit, onBrandSubmit, onNationSubmit, onPlatformSubmit, onInfringeSubmit, onSearchTextSubmit, onSortSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "todayCount": dataParam.today, "accumCount": dataParam.accum, "page": pageParam, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const BODY_COUNT_PER_PAGE = 24
  const navigate = useNavigate();

  // 검색 옵션
  const searchsDict = [
    { value: '', label: '전체' },
    { value: 'determin_name', label: '제목' },
    { value: 'seller', label: '판매상' },
  ];

  // 정렬
  const sortsDict = [
    { value: 'price_low', label: '가격낮은순' },
    { value: 'price_high', label: '가격높은순' },
    { value: 'volume_small', label: '판매량적은순' },
    { value: 'volume_large', label: '판매량많은순' },
  ];
  const [sort, setSort] = useState(sortParam);
  const handleSort = (e) => {
    let value = e.target.value;
    setSort(value);
    onSortSubmit(value);
  };

  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 브랜드 코드 구하기기
  const PullBrandCode = () => {
    let formData = { include: 'all' }
    ProductApi.getBrandCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setBrand(value);
        setBrands(response.data.list);
        // refBrand.current = value;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 브랜드
  const [brand, setBrand] = useState(brandParam);
  const [brands, setBrands] = useState([])
  const handleBrand = (e) => {
    let value = e.target.value;
    setBrand(value);
    onBrandSubmit(value);
    setPage(1);
  };

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            // setContinent(continentParam);
            // setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            setNation(nationParam);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', nationParam, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 국가
  const [nation, setNation] = useState(nationParam);
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', '1');
    } else {
      PullComCode('nation', value, 1);
    }
    onNationSubmit(value, '');
  };

  // 플랫폼
  const [platform, setPlatform] = useState(platformParam);
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 침해유형
  const [infringe, setInfringe] = useState(infringeParam);
  const handleInfringe = (e) => {
    let value = e.target.value;
    setInfringe(value);
    onInfringeSubmit(value);
  };

  function disLR(lr) {
    let disabled = false;
    if (lr === "left") {
      if (page <= 1)
        disabled = true;
    } else {
      let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);  
      if (page >= totalPage)
        disabled = true;
    }
    // console.log(`${lr} => ${disabled}`);
    return disabled;
  }
  
  // 페이지 이동
  const [page, setPage] = useState(pageParam);
  const handlePage = (e, value) => {
    value = page + value;
    let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);    
    if (value <= 0) value = 1;
    else if (value > totalPage) value = totalPage;
    setPage(value);
    onPageSubmit(value);
  };

  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  const handleSellerHome = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  }

  // 상세 페이지 이동
  const handleDetailSubmit = (id) => {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('infringe', infringe);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('sort', sort);
    localStorage.setItem('page', page);
    navigate(`/forgerydetail/${id}`);
  }

  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }


  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    // 브랜드
    PullBrandCode();

    // 국가/플랫폼 코드
    PullComCode('continent', '', '1');  // level(0: continent, 1: nation,2: platform)
  },[]);

  return (
    <div className="forgery-list">
      <div className="div-19">
        <div className="middle-section-3">
          <LeftMenu className="left-menu-9" />
          <div className="body-area-3">
            <div className="body-title-6">
              <div className="title-9">
                오늘 : {pageInfo && pageInfo.todayCount.toLocaleString('ko-KR')} 건
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                누적 : {pageInfo && pageInfo.accumCount.toLocaleString('ko-KR')} 건
              </div>
            </div>

            <div className="body-head-4">
              <div className="search-3">
                <div className="dropdown-12">
                  {/* <div className="text-wrapper-52">판매상</div> */}
                  <select className="text-wrapper-52" style={{width:"80px", height:"25px", border:"none"}} id="searchType" name="searchType" value={searchType} onChange={(e) => handleSearchType(e)}>
                    { searchsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>

                <div className="dropdown-13">
                  {/* <div className="element-3">저작권</div> */}
                  <input className="element-3" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="검색" style={{width:"80px", height:"25px", border:"none"}}/>

                </div>
              </div>

              <div className="div-20">
                <div className="dropdown-14">
                  <div className="text-wrapper-53">정렬</div>
                </div>

                <div className="dropdown-13">
                  {/* <div className="text-wrapper-52">저작권</div> */}
                  <select className="text-wrapper-52" style={{width:"80px", height:"25px", border:"none"}} id="sort" name="sort" value={sort} onChange={(e) => handleSort(e)}>
                    { sortsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>
              </div>

              <div className="div-20">
                <div className="dropdown-15">
                  <div className="text-wrapper-53">브랜드</div>
                </div>

                <div className="dropdown-13">
                  {/* <div className="text-wrapper-52">저작권</div> */}
                  <select className="text-wrapper-52" style={{width:"80px", height:"25px", border:"none"}} id="brand" name="brand" value={brand} onChange={(e) => handleBrand(e)}>
                    { brands.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="div-20">
                <div className="dropdown-14">
                  <div className="text-wrapper-53">국가</div>
                </div>

                <div className="dropdown-13">
                  {/* <div className="text-wrapper-52">저작권</div> */}
                  <select className="text-wrapper-52" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                    { nations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="platform">
                <div className="dropdown-15">
                  <div className="text-wrapper-53">플랫폼</div>
                </div>

                <div className="dropdown-13">
                  {/* <div className="text-wrapper-52">저작권</div> */}
                  <select className="text-wrapper-52" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                    { platforms.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="infringe">
                <div className="dropdown-16">
                  <div className="text-wrapper-53">침해유형</div>
                </div>

                <div className="dropdown-13">
                  {/* <div className="text-wrapper-52">저작권</div> */}
                  <select className="text-wrapper-52" style={{width:"80px", height:"25px", border:"none"}} id="infringe" name="infringe" value={infringe} onChange={(e) => handleInfringe(e)}>
                    { infringeTypeOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              {/* <div className="left-right-frame-3">
                <div className="div-wrapper-4">
                  <div className="text-wrapper-54"></div>
                </div>

                <div className="div-wrapper-4">
                  <div className="text-wrapper-54"></div>
                </div>
              </div> */}
              <div className="left-right-frame-3">
                <button className="div-wrapper-4" disabled={pageInfo && disLR('left') ? true : false} style={{cursor: (pageInfo && disLR('left')) ? null : 'pointer'}} onClick={(e) => handlePage(e, -1)}>
                  <div className="text-wrapper-54"><FontAwesomeIcon icon={faChevronLeft} /></div>
                </button>

                <button className="div-wrapper-4" disabled={pageInfo && disLR('right') ? true : false} style={{cursor: (pageInfo && disLR('right')) ? null : 'pointer'}} onClick={(e) => handlePage(e, 1)}>
                  <div className="text-wrapper-54"><FontAwesomeIcon icon={faChevronRight} /></div>
                </button>
              </div>


            </div>

            <div className="best-body-3">


              {datas && datas.map((dtm, index, total) => (
              <div className="best-item-3" key={dtm.id}>
                <div className="thumbnail-10">
                  <div className="overlap-group-7" style={{cursor:'pointer', backgroundImage:`url(${displayImage(dtm.determin_image1)})`, backgroundSize:'cover'}}  onClick={()=>{handleDetailSubmit(dtm.id)}}>
                    <button className="button-9">
                      <div className="button-box-8"></div>
                    </button>
                  </div>
                </div>

                <div className="item-info-3">
                  <div className="item-title-2">
                    <p className="item-title-text-3">
                    {dtm.determin_name.substring(0,30)}
                    </p>
                  </div>

                  <div className="div-21">
                    <div className="text-wrapper-55">
                      {dtm.krw_exrate ? 'KRW' : dtm.currency.toUpperCase()}
                      &nbsp;
                      {dtm.krw_exrate ? (dtm.determin_price * dtm.krw_exrate).toLocaleString('ko-KR') : dtm.determin_price.toLocaleString('ko-KR')}
                      &nbsp;/&nbsp;
                      {dtm.krw_exrate ? (dtm.price_floor * dtm.krw_exrate).toLocaleString('ko-KR') : dtm.price_floor.toLocaleString('ko-KR')}
                    </div>

                    <div className="text-wrapper-56">
                    </div>

                    <div className="text-wrapper-57">
                    </div>
                  </div>

                  <div className="div-21">
                    <div className="product-price-3">
                    {dtm.mall}
                    </div>

                    <div className="price-floor-3">
                    </div>

                    <div className="determin-price-3">
                    </div>
                  </div>

                  <div className="div-21">
                    <div className="text-wrapper-55" onClick={(e) => handleSellerHome(e, dtm.seller_home)}>
                    {dtm.seller.substring(0,10)}
                    </div>

                    <div className="text-wrapper-56">
                    </div>

                    <div className="text-wrapper-57" style={{color:'tomato'}}>
                    {infringeTypes[dtm.infringe].substring(0,10)}
                    </div>
                    
                  </div>
                </div>
              </div>

              // </Link>
              ))}
              
            </div>
          </div>
        </div>

        <div className="menu-section-3">
          <MainLogo className="main-logo-8" />
          <RightMenu className="right-menu-9" />
        </div>
      </div>
    </div>
  );
};
