import React from "react";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";

export const InvestgateDetail = () => {
  return (
    <div className="investgate-detail">
      <div className="div-2">
        <div className="bulletin-body">
          <div className="body">
            <div className="body-title">
              <div className="title">조사 요청 세부 내역</div>
            </div>

            <div className="body-sub-title" />

            <div className="body-head">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-12">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  <div className="text-wrapper-13">저작권</div>
                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-12">카테고리</div>
                </div>

                <div className="dropdown-2">
                  <div className="text-wrapper-13">저작권</div>
                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-12">침해유형</div>
                </div>

                <div className="dropdown-2">
                  <div className="text-wrapper-13">저작권</div>
                </div>
              </div>

              <BodySearch className="body-search-instance" />
            </div>
          </div>
        </div>

        <FooterPanel className="footer-panel-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="left-menu-instance" />
      </div>
    </div>
  );
};
