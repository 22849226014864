import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
// import { FooterPanel } from "../../components/FooterPanel";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
// import DatePicker from 'react-datepicker';
import { DatePickerInput } from "../../components/DatePickerInput";
import { Input } from "../../components/Input";
import { lawsuitLevels, lawsuitLevelOptions } from "../../config/codes";


export const LawsuitList = ({ dataParam, currPage, onPlatformSubmit, onProgressSubmit, onToDateSubmit, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 플랫폼  
  const platformOptions = [
    { value: 'all', label: '전체' },
    { value: 'alibaba', label: '알리바바' },
    { value: 'temu', label: '태무' },
    { value: '1688', label: '일육팔팔' },
    { value: 'taobao', label: '타오바오' },
    { value: 'lazada', label: '라자다' },
    { value: 'amazon_us', label: '아마존_미국' },
    { value: 'shopee', label: '쇼피' },
    { value: 'naver', label: '네이버쇼핑' },
    { value: 'coupang', label: '쿠팡' },
  ];  
  const platforms = {
    'alibaba': '알리바바',
    'temu': '태무',
    '1688': '일육팔팔',
    'taobao': '타오바오',
    'lazada': '라자다',
    'amazon_us': '아마존_미국',
    'shopee': '쇼피',
    'naver': '네이버쇼핑',
    'coupang': '쿠팡',
  }
  const [platform, setPlatform] = useState('all');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

 
  const [progress, setProgress] = useState('all');
  const handleProgress = (e) => {
    let value = e.target.value;
    setProgress(value);
    onProgressSubmit(value);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const datePickerRef = useRef(null); //달력아이콘 클릭 시 클릭해줄 위치(DatePicker)
  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };
  const [toDate, setToDate] = useState(null);  
  const handleToDate = (date) => {
    setToDate(date); // SelectDate 컴포넌트로부터 전달받은 selectedDate 값을 설정
    onToDateSubmit(date);
  };
  // 세부검색
  const searchOptions = [
    { value: 'all', label: '전체' },
    { value: 'seller', label: '판매상' },
    { value: 'product', label: '제품명' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="lawsuit-list">
      <div className="div-5">
        <div className="body-wrapper">
          <div className="body-2">
            <div className="bulletin-body-title-3">
              <div className="title-4">IP 소송 금융</div>
            </div>

            <div className="body-sub-title-3" />

            <div className="head-2">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-31">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-31">저작권</div> */}

                  <select className="text-wrapper-31" style={{width:"100px", height:"26px", border:"none"}} id="platform" name="platform" value={platform} onChange={handlePlatform}>
                  { platformOptions.map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>

                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-31">진행상황</div>
                </div>

                <div className="dropdown-4">

                  <select className="text-wrapper-31" style={{width:"95px", height:"26px", border:"none"}} id="progress" name="progress" value={progress} onChange={handleProgress}>
                  { progressOptions.map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>

                </div>

                <div className="dropdown-5">
                  <div className="text-wrapper-31">일자</div>
                </div>

                {/* <div className="dropdown-4">
                  <div className="text-wrapper-31">저작권</div>
                </div> */}
                <input className="dropdown-2" text="" name={toDate} value={toDate} onChange={handleToDate} ref={refToDate} >
                </input>                
              </div>

              {/* <BodySearch className="design-component-instance-node-2" /> */}
              {pageInfo ?
              <BodySearch className="design-component-instance-node-2" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="form-table-wrapper">
              <div className="form-table-2">

                <div className="head-frame-2">
                  <div className="body-row-cell-5">
                    <div className="text-wrapper-32">번호</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-33">플랫폼</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-33">진행상황</div>
                  </div>

                  <div className="body-row-cell-7">
                    <div className="text-wrapper-34">제품명</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-32">판매상</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-32">공증번호</div>
                  </div>

                  <div className="body-row-cell-8">
                    <div className="text-wrapper-32">판매</div>
                  </div>

                  <div className="body-row-cell-8">
                    <div className="text-wrapper-32">제출일</div>
                  </div>

                  <div className="body-row-cell-8">
                    <div className="text-wrapper-32">입안일</div>
                  </div>

                  <div className="body-row-cell-8">
                    <div className="text-wrapper-32">공증일</div>
                  </div>

                  <div className="body-row-cell-9">
                    <div className="text-wrapper-32">종결</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame-2" to={`/lawsuitdetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data-6">
                    <div className="text-wrapper-35">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-7">
                    <div className="text-wrapper-35">{platforms[prd.platform]}</div>
                  </div>

                  <div className="body-row-data-7">
                    <div className="text-wrapper-35">{lawsuitLevels[prd.progress]}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-35">{prd.product}</div>
                  </div>

                  <div className="body-row-data-9">
                    <div className="text-wrapper-36">{prd.seller_name}</div>
                  </div>

                  <div className="body-row-data-9">
                    <div className="text-wrapper-35">{prd.notarization_no}</div>
                  </div>

                  <div className="body-row-data-10">
                    <div className="text-wrapper-35">{prd.sold_type}</div>
                  </div>

                  <div className="body-row-data-10">
                    <div className="text-wrapper-35">{prd.submit_at ? prd.submit_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-10">
                    <div className="text-wrapper-35">{prd.entried_at ? prd.entried_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-10">
                    <div className="text-wrapper-35">{prd.notarized_at ? prd.notarized_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-11">
                    <div className="text-wrapper-35">{prd.finished}</div>
                  </div>

                </Link>
                )) : <></>}


              </div>
            </div>

            {/* <BodyPage className="design-component-instance-node-2" /> */}
            {pageInfo ?
              <BodyPage className="design-component-instance-node-2" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>

        {/* <FooterPanel className="footer-panel-4" /> */}
        <LeftMenu className="left-menu-4" />
        <MainLogo className="main-logo-4" />
        <RightMenu className="right-menu-4" />
      </div>
    </div>
  );
};
