import React, {useState, useEffect } from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import CodeApi from "../../api/codes";
import ProductApi from "../../api/products";
import { getForgeryList, clearForgeryList } from "../../modules/forgerys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import CollectApi from "../../api_elastic/collects";
import moment from "moment"

import { infringeTypes, infringeTypeOptions } from "../../config/codes";

export const CollectList = ({ dataParam, brandParam, nationParam, platformParam, searchTypeParam, searchTextParam, pageParam, onPageSubmit, onBrandSubmit, onNationSubmit, onPlatformSubmit, onSearchTextSubmit }) => {
  const datas = dataParam ? dataParam.hits : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.total.value} : null;
  const BODY_COUNT_PER_PAGE = 24

  const navigate = useNavigate();
  const [todayCount, setTodayCount] = useState(0);
  const [accumCount, setAccumCount] = useState(0);

  // 검색 옵션
  const searchsDict = [
    { value: '', label: '제목+판매상' },
    { value: 'determin_name', label: '제목' },
    { value: 'seller', label: '판매상' },
  ];

  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 브랜드 코드 구하기기
  const PullBrandCode = () => {
    let formData = { include: 'all' }
    ProductApi.getBrandCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setBrand(value);
        setBrands(response.data.list);
        // refBrand.current = value;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 브랜드
  const [brand, setBrand] = useState(brandParam);
  const [brands, setBrands] = useState([])
  const handleBrand = (e) => {
    let value = e.target.value;
    setBrand(value);
    onBrandSubmit(value);
    setPage(0);
  };

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            // setContinent(continentParam);
            // setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            setNation(nationParam);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', nationParam, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 국가
  const [nation, setNation] = useState(nationParam);
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', '1');
    } else {
      PullComCode('nation', value, 1);
    }
    onNationSubmit(value, '');
    setPage(0);
  };

  // 플랫폼
  const [platform, setPlatform] = useState(platformParam);
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
    setPage(0);
  };

  function disLR(lr) {
    let disabled = false;
    if (lr === "left") {
      if (page <= 0)
        disabled = true;
    } else {
      let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);  
      if (page >= totalPage-1)
        disabled = true;
    }
    // console.log(`${lr} => ${disabled}`);
    return disabled;
  }
  
  // 페이지 이동
  const [page, setPage] = useState(pageParam);
  const handlePage = (e, value) => {
    value = page + value;
    let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);    
    if (value <= 0) value = 0;
    else if (value > totalPage) value = totalPage-1;
    setPage(value);
    onPageSubmit(value);
  };

  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  const handleSellerHome = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  }

  // 오늘 수집 건수
  const GetTodayCollectCount = () => {
    const frDt = moment().format("YYYY-MM-DD") + "T00:00:00";       // 오늘 00시00분00초
    const frDate = moment(frDt).utc().format("YYYY-MM-DDTHH:mm:ss") // utc 시간 변환
    const toDt = moment().format("YYYY-MM-DDTHH:mm:ss");            // 오늘 현재 시간
    const toDate = moment(toDt).utc().format("YYYY-MM-DDTHH:mm:ss") // utc 시간 변환
    let params = { 
      query : { 
        bool: { 
          filter: 
          [ 
            // { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "collect_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { collect_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "collect_name"], query: `${searchText}` }})
    }
    params.query.bool.filter.push(   { "range": { "created_at": { "gte": `${frDate}`, "lt": `${toDate}` } } }    )
    CollectApi.getCollectCount(params).then(response => {
      if(response.status === 200) {
        // console.log(JSON.stringify(params));
        setTodayCount(response.data.count);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
  }

  // 누적 수집 건수
  const GetAccumCollectCount = () => {
    let params = { 
      query : { 
        bool: { 
          filter: 
          [ 
            // { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "collect_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { collect_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "collect_name"], query: `${searchText}` }})
    }
    CollectApi.getCollectCount(params).then(response => {
      if(response.status === 200) {
        // console.log(JSON.stringify(params));
        setAccumCount(response.data.count);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
  }

  // 상세 페이지 이동
  const handleDetailSubmit = (_id) => {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
    navigate(`/collectdetail/${_id}`);
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    // 브랜드
    PullBrandCode();

    // 국가/플랫폼 코드
    PullComCode('continent', '', '1');

  },[]);

  useEffect(() => {
    // 오늘 수집품 건수
    GetTodayCollectCount();
    // 누적 수집품 건수
    GetAccumCollectCount();

  },[brand, nation, platform]);

  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }

  return (
    <div className="collect-list">
      <div className="div-2">
        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-area">
            <div className="body-title">
              {/* <div className="title"> 오늘 : 87건&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;누적: 877건
              </div> */}
              <div className="title">
                오늘 : {todayCount.toLocaleString('ko-KR')} 건
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                누적 : {accumCount.toLocaleString('ko-KR')} 건
              </div>

            </div>

            <div className="body-head">
              <div className="search">
                <div className="dropdown">
                  {/* <div className="text-wrapper-6">판매상</div> */}
                  <select className="text-wrapper-6" style={{width:"80px", height:"25px", border:"none"}} id="searchType" name="searchType" value={searchType} onChange={(e) => handleSearchType(e)}>
                    { searchsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>

                <div className="dropdown-2">
                  {/* <div className="element">저작권</div> */}
                  <input className="element" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="검색" style={{width:"80px", height:"25px", border:"none"}}/>

                </div>
              </div>

              <div className="div-3">
                <div className="dropdown-4">
                  <div className="text-wrapper-7">브랜드</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-6">저작권</div> */}
                  <select className="text-wrapper-6" style={{width:"80px", height:"25px", border:"none"}} id="brand" name="brand" value={brand} onChange={(e) => handleBrand(e)}>
                    { brands.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="nation">
                <div className="dropdown-3">
                  <div className="text-wrapper-7">국가</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-6">저작권</div> */}
                  <select className="text-wrapper-6" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                    { nations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="div-3">
                <div className="dropdown-4">
                  <div className="text-wrapper-7">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-6">저작권</div> */}
                  <select className="text-wrapper-6" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                    { platforms.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              {/* <div className="left-right-frame">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-8"></div>
                </div>

                <div className="div-wrapper-2">
                  <div className="text-wrapper-8"></div>
                </div>
              </div> */}
              <div className="left-right-frame">
                <button className="div-wrapper-2" disabled={pageInfo && disLR('left') ? true : false} style={{cursor: (pageInfo && disLR('left')) ? null : 'pointer'}} onClick={(e) => handlePage(e, -1)}>
                  <div className="text-wrapper-8"><FontAwesomeIcon icon={faChevronLeft} /></div>
                </button>

                <button className="div-wrapper-2" disabled={pageInfo && disLR('right') ? true : false} style={{cursor: (pageInfo && disLR('right')) ? null : 'pointer'}} onClick={(e) => handlePage(e, 1)}>
                  <div className="text-wrapper-8"><FontAwesomeIcon icon={faChevronRight} /></div>
                </button>
              </div>

            </div>

            <div className="best-body">

              {datas && datas.map((dtm, index, total) => (
              <div className="best-item" onClick={()=>{handleDetailSubmit(dtm._id)}}  key={dtm._id}>

                <div className="thumbnail">
                  <div className="overlap-group" style={{cursor:'pointer', backgroundImage: `url(${dtm._source.thumb1})`, backgroundSize:'corner'}}>
                    <button className="button-box-wrapper">
                      <div className="button-box"></div>
                    </button>
                  </div>
                </div>

                <div className="item-info">
                  <div className="item-title">
                    <p className="item-title-text">
                    {dtm._source.determin_name && dtm._source.determin_name.substring(0,30)}
                    </p>
                  </div>

                  <div className="div-4">
                    <div className="text-wrapper-9">
                      {dtm._source.krw_exrate ? 'KRW' : dtm._source.currency.toUpperCase()}
                      &nbsp;
                      {dtm._source.krw_exrate ? (dtm._source.determin_price * dtm._source.krw_exrate).toLocaleString('ko-KR') : dtm._source.determin_price.toLocaleString('ko-KR')}
                      &nbsp;/&nbsp;
                      {dtm._source.krw_exrate ? (dtm._source.price_floor * dtm._source.krw_exrate).toLocaleString('ko-KR') : dtm._source.price_floor.toLocaleString('ko-KR')}

                    </div>

                    <div className="text-wrapper-10">
                    </div>

                    <div className="text-wrapper-11">
                    </div>
                  </div>

                  <div className="div-4">
                    <div className="product-price">
                      {dtm._source.mall}

                    </div>

                    <div className="price-floor">
                    </div>

                    <div className="determin-price">
                    </div>
                  </div>

                  <div className="div-4">
                    <div className="text-wrapper-9" onClick={(e) => handleSellerHome(e, dtm._source.seller_home)}>
                      {dtm._source.seller && dtm._source.seller.substring(0,10)}
                    </div>

                    <div className="text-wrapper-10">

                    </div>

                    <div className="text-wrapper-11">
                      {dtm._source.created_at.substring(0,10)}
                    </div>
                  </div>
                </div>
              </div>

              ))}
              
            </div>
          </div>
        </div>

        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>
      </div>
    </div>
  );
};
