import React from "react";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";

export const AssessDetail = () => {
  return (
    <div className="assess-detail">
      <div className="div-4">
        <div className="bulletin-body-2">
          <div className="body-3">
            <div className="body-title-2">
              <div className="title-3">구매 감정 세부 내역</div>
            </div>

            <div className="body-sub-title-3" />

            <div className="body-head-3">
              <div className="drop-downs-3">
                <div className="dropdown-7">
                  <div className="text-wrapper-16">플랫폼</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>

                <div className="dropdown-9">
                  <div className="text-wrapper-16">카테고리</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>

                <div className="dropdown-9">
                  <div className="text-wrapper-16">침해유형</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>
              </div>

              <BodySearch className="body-search-2" />
            </div>
          </div>
        </div>

        <FooterPanel className="footer-panel-3" />
        <MainLogo className="main-logo-3" />
        <RightMenu className="right-menu-3" />
        <LeftMenu className="left-menu-3" />
      </div>
    </div>
  );
};
