import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const BodySearch = ({
  className,
  optionsClassName="optionsClassName",
  divClassName="divClassName",
  inputClassName="inputClassName",
  divClassNameOverride="divClassNameOverride",
  buttonClassName="buttonClassName",
  divClassName1="divClassName1",
  text = "키워드 입력",
  searchOptions, onSearchSubmit
}) => {

  const [selectedSearchOption, setSelectedSearchOption] = useState('');
  const handleSelectSearchChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const [formData, setFormData] = useState({
    "searchType": "",
    "search": ""
  });

  const { searchType, search } = formData; 

  const onChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });

  };

  const onKeyDown = (e) => {
    if(e.key === "Enter" && search != "") {
      if (search != "") {
        onSearchSubmit(searchType, search);
      } 
    }
  } 
  
  const onClick = e => {
    e.preventDefault();
    onSearchSubmit(searchType, search);
  } 

  return (
    <div className={`body-search ${className}`} >

      {/* <div className={`options ${optionsClassName}`} style={{height:"28px"}} id="searchType" name="searchType" value={searchType} onChange={handleSelectSearchChange}>
        <div className={`text-wrapper-10 ${divClassName}`}>제목+내용</div>
      </div> */}

      <select className={`options ${optionsClassName}`} style={{height:"28px"}} id="searchType" name="searchType" value={searchType} onChange={handleSelectSearchChange}>
        { searchOptions.map((option, index, total) => ( 
          <option className="text-wrapper-10 ${divClassName}" value={option.value}>{option.label}</option>
        ))}
      </select>

      {/* <div className={`input ${inputClassName}`}>
        <div className={`text-wrapper-11 ${divClassNameOverride}`}>{text}</div>
      </div> */}

      <input className="input ${inputClassName}" type="text" name='search' value={search} onChange={onChange} onKeyDown={(e) => onKeyDown(e)} />


      {/* <button className={`button-2 ${buttonClassName}`}>
        <div className={`text-wrapper-12 ${divClassName1}`}>검색</div>
      </button> */}

      <button className={`button-2 ${buttonClassName}`} style={{cursor:'pointer'}} onClick={onClick}>
        <div className={`text-wrapper-12 ${divClassName1}`}>검색</div>
      </button>      
    </div>
  );
};
