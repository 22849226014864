import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MonitoringList } from "../components/MonitoringList";
import { getMonitoringList } from "../modules/monitorings";
import { useAuth } from "../contexts/auth/auth.context";

export const MonitoringListContainer = ({pageParam = 1, monthParam = '', nationParam = '', platformParam = '', infringeTypeParam = '', productNameParam = ''}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ page, setPage ] = useState(pageParam);
  const [ month, setMonth ] = useState(monthParam);
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ infringeType, setInfringeType ] = useState(infringeTypeParam);
  const [ productName, setProductName ] = useState(productNameParam);

  const { data, loading, error} = useSelector((state) => state.monitoringReducer.monitoringlist);
  const dispatch = useDispatch();

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onMonthSubmit = (monthParam) => {
    setPage(1);
    setMonth(monthParam);
  }

  const onNationSubmit = (nationParam) => {
    setPage(1);
    setNation(nationParam);
  }

  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  const onInfringeTypeSubmit = (infringeTypeParam) => {
    setPage(1);
    setInfringeType(infringeTypeParam);
  }

  const onProductNameSubmit = (productNameParam) => {
    setPage(1);
    setProductName(productNameParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getMonitoringList(page, month, nation, platform, infringeType, productName));
  }, [dispatch, page, month, nation, platform, infringeType, productName]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <MonitoringList 
    dataParam={data}
    currPage = {page}
    onPageSubmit={onPageSubmit} 
    onMonthSubmit={onMonthSubmit} 
    onNationSubmit={onNationSubmit} 
    onPlatformSubmit={onPlatformSubmit} 
    onInfringeTypeSubmit={onInfringeTypeSubmit}
    onProductNameSubmit={onProductNameSubmit}
    />
  );
}