import * as dashboardApi from "../api/dashboards";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_DASHBOARDLIST = "GET_DASHBOARDLIST";
const GET_DASHBOARDLIST_SUCCESS = "GET_DASHBOARDLIST_SUCCESS";
const GET_DASHBOARDLIST_ERROR = "GET_DASHBOARDLIST_ERROR";

// 상품 항목 조회하기
const GET_DASHBOARDDETAIL = "GET_DASHBOARDDETAIL";
const GET_DASHBOARDDETAIL_SUCCESS = "GET_DASHBOARDDETAIL_SUCCESS";
const GET_DASHBOARDDETAIL_ERROR = "GET_DASHBOARDDETAIL_ERROR";

const CLEAR_DASHBOARDTLIST = "CLEAR_DASHBOARDTLIST";

export const getDashboardList = (page, month, nation, platform, infringeType, productName) => ({
  type: GET_DASHBOARDLIST,
  payload: { page: page, month: month, nation: nation, platform: platform, infringeType: infringeType, productName: productName},
});

export const getDashboardDetail = (access, id) => ({
  type: GET_DASHBOARDDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearDashboardList = () => ({ type: CLEAR_DASHBOARDLIST });

const getDashboardListSaga = createPromiseSagaList(
  GET_DASHBOARDLIST,
  dashboardApi.getDashboardList
);

const getDashboardDetailSaga = createPromiseSagaDetail(
  GET_DASHBOARDDETAIL,
  dashboardApi.getDashboardDetail
);

// 사가들을 합치기
export function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARDLIST, getDashboardListSaga);
  yield takeEvery(GET_DASHBOARDDETAIL, getDashboardDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  dashboardlist: reducerUtils.initial(),
  dashboarddetail: reducerUtils.initial(),
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARDLIST:
    case GET_DASHBOARDLIST_SUCCESS:
    case GET_DASHBOARDLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_DASHBOARDLIST,
        "dashboardlist",
        true
      );
      return reducerList(state, action);

    case GET_DASHBOARDDETAIL:
    case GET_DASHBOARDDETAIL_SUCCESS:
    case GET_DASHBOARDDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_DASHBOARDDETAIL,
        "dashboarddetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_DASHBOARDTLIST:
      return {
        ...state,
        dashboardlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
