import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
// import { FooterPanel } from "../../components/FooterPanel";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
// import DatePicker from 'react-datepicker';
import { DatePickerInput } from "../../components/DatePickerInput";
import { Input } from "../../components/Input";
import { lawsuitLevels, lawsuitLevelOptions } from "../../config/codes";

export const AssessList = ({ dataParam, currPage, onPlatformSubmit, onProgressSubmit, onToDateSubmit, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 플랫폼  
  const platformOptions = [
    { value: 'all', label: '전체' },
    { value: 'alibaba', label: '알리바바' },
    { value: 'temu', label: '태무' },
    { value: '1688', label: '일육팔팔' },
    { value: 'taobao', label: '타오바오' },
    { value: 'lazada', label: '라자다' },
    { value: 'amazon_us', label: '아마존_미국' },
    { value: 'shopee', label: '쇼피' },
    { value: 'naver', label: '네이버쇼핑' },
    { value: 'coupang', label: '쿠팡' },
  ];  
  const platforms = {
    'alibaba': '알리바바',
    'temu': '태무',
    '1688': '일육팔팔',
    'taobao': '타오바오',
    'lazada': '라자다',
    'amazon_us': '아마존_미국',
    'shopee': '쇼피',
    'naver': '네이버쇼핑',
    'coupang': '쿠팡',
  }
  const [platform, setPlatform] = useState('all');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };


  const [progress, setProgress] = useState('all');
  const handleProgress = (e) => {
    let value = e.target.value;
    setProgress(value);
    onProgressSubmit(value);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const datePickerRef = useRef(null); //달력아이콘 클릭 시 클릭해줄 위치(DatePicker)
  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };
  const [toDate, setToDate] = useState(null);  
  const handleToDate = (date) => {
    setToDate(date); // SelectDate 컴포넌트로부터 전달받은 selectedDate 값을 설정
    onToDateSubmit(date);
  };
  // 세부검색
  const searchOptions = [
    { value: 'all', label: '전체' },
    { value: 'seller', label: '판매상' },
    { value: 'product', label: '제품명' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="assess-list">
      <div className="div-4">
        <div className="bulletin-body-2">
          <div className="body-3">
            <div className="body-title-2">
              <div className="title-3">구매 감정</div>
            </div>

            <div className="body-sub-title-3" />

            <div className="body-head-3">
              <div className="drop-downs-3">
                <div className="dropdown-7">
                  <div className="text-wrapper-16">플랫폼</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>

                <div className="dropdown-9">
                  <div className="text-wrapper-16">카테고리</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>

                <div className="dropdown-9">
                  <div className="text-wrapper-16">침해유형</div>
                </div>

                <div className="dropdown-8">
                  <div className="text-wrapper-17">저작권</div>
                </div>
              </div>

              {/* <BodySearch className="body-search-2" /> */}
              {pageInfo ?
              <BodySearch className="body-search-2" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>
          </div>
        </div>

        {/* <FooterPanel className="footer-panel-3" /> */}
        <MainLogo className="main-logo-3" />
        <RightMenu className="right-menu-3" />
        <LeftMenu className="left-menu-3" />
      </div>
    </div>
  );
};
