import axios from "./index";

// 상품 목록
export const getProductList = async (params) => {
  const response = await axios.get("/products/", { params: params });
  return response.data;
};

// 상품 상세 정보
export const getProductDetail = async (params) => {
  const response = await axios.get(`/products/${params.id}/`) //, { headers: { Authorization: `Bearer ${params.access}` } });
  return response.data;
};

// 특정 상품에 대한 가격 목록
export const getProductPolicys = async (params) => {
  const response = await axios.get(`/productpolicys/${params.category}/`);
  return response.data;
};

// 특정 상품에 대한 리뷰 목록
export const getProductReviews = async (params) => {
  const response = await axios.get(`/productreviews/${params.id}/`);
  return response.data;
};

// 특정 상품에 대한 가격 목록
export const getProductPrices = async (params) => {
  const response = await axios.get(`/productprices/${params.id}/`);
  return response.data;
};

// 내가 찜한 상품 목록
export const getProductJjimeds = async (params) => {
  const response = await axios.get(`/productsimilars/${params.category}/`); // , { params: params }
  return response.data;
};

// 내가 본 상품 목록
export const getProductVieweds = async (params) => {
  const response = await axios.get("/productvieweds/", { params: params });
  return response.data;
};

// 상품에 대한 유사 상품 목록
export const getProductSimilars = async (params) => {
  const response = await axios.get(`/productsimilars/${params.category}/`); //, { params: params });
  return response.data;
};

// 장바구니 상품들 구독 신청
export const postProductSubscribes = (data, header) => {
  const response = axios.post(`/productsubscribes/`, data, { headers: { Authorization: `Bearer ${header.access}` }});
  return response;
};

class ProductApi {
  // 브랜드명
  static getBrandCode = (data) => {
    const response = axios.post(`/brands/`, data, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    return response; 
  };

  // 상품 데이터 수정
  static UpdateProduct = (id, formData) => {
    let data = `/products/${id}/`
    return axios.post(data, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  
}
export default ProductApi;
