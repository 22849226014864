import React, {useState, useRef, useContext } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";
import useForm from '../hooks/useForm';
import AlertContext from '../../contexts/alert/AlertContext';
import { Input } from "../../components/Input";
export const SignupRegistry = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category")

  const { user, setUser, global, setGlobal } = useAuth();
  const [error, setError] = useState("");
  const { alert: alertComp } = useContext(AlertContext);


  const useridInput = useRef();
  const domainInput = useRef();
  const authCodeInput = useRef();
  const passwordInput = useRef();
  const passwordConfirmInput = useRef();
  const mobileInput = useRef();
  const usernameInput = useRef();
  const received_auth_code = useRef('')

  const [{userid, domain, auth_code, password, password_confirm, mobile, username}, onChange, onReset] = useForm({
    'userid': '',
    'domain': '',
    'auth_code': '',
    'password': '',
    'password_confirm': '',
    'mobile': '',
    'username': ''
  });

  const onSubmit = (rolename, e) => {
    e.preventDefault();
    if (rolename == 'auth_code') {
      if (userid.trim().length == 0) {
        const result = alert("아이디를 입력해 주세요!");
        useridInput.current.focus();
        return false;
      }
      else if (domain.trim().length == 0) {
        const result = alert("도메인명을 입력해 주세요!");
        domainInput.current.focus();
        return false;
      }
      else {
        let email = `${userid.trim()}@${domain.trim()}`;
        let formData = { email: email };
        AuthApi.SendEmailAuth(formData).then(response => {
          if(response.status == 200) {
            received_auth_code.current = response['data']['code']
            alert(`${email} 메일로 인증번호를 전송했습니다.`);
            authCodeInput.current.focus();
          } else {
            alert(response.data.msg)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
      }
    }
    else if (rolename == 'confirm') {
      if (validateInputs() == true) {
        let email = `${userid}@${domain}`;
        let formData = {
          'email': email.trim(),
          'password': password.trim(),
          'mobile': mobile.trim(),
          'username': username.trim()
        }
        AuthApi.Register(formData).then(response => {
          if ((response.status === 200 || response.status === 201) && response.data.success === true) {
            alert(response.data.msg)
            navigate('/');
          } else {
            alert(response.data.msg)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
      }
    }
    else if (rolename == 'backward') {
      navigate(-1); // 이전 페이지로 이동
    }
  }

  // validate Input Columns
  const validateInputs =() => {
    if (userid.trim() < 4) {
      const result = alert("아이디(4바이트 이상)를 입력해 주세요!");
      useridInput.current.focus();
      return false;
    }
    else if (domain.trim() == 0) {
      const result = alert("도메인명을 입력해 주세요!");
      domainInput.current.focus();
      return false;
    }
    else if (received_auth_code.current == '') {
      const result = alert("'이메일 인증'을 해주세요!");
      return false;
    }
    else if (auth_code.trim() !== received_auth_code.current) {
      const result = alert("인증 번호가 일치하지 않습니다!");
      authCodeInput.current.focus();
      return false;
    }
    else if (password.trim() == 0) {
      const result = alert("비밀번호를 해주세요!");
      passwordInput.current.focus();
      return false;
    }
    else if (password_confirm.trim() == 0) {
      const result = alert("비밀번호 확인을 입력해 주세요!");
      passwordConfirmInput.current.focus();
      return false;
    }
    else if (password!= password_confirm) {
      const result = alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      passwordConfirmInput.current.focus();
      return false;
    }
    else if (mobile.trim() == 0) {
      const result = alert("핸드폰 번호를 입력해 주세요!");
      mobileInput.current.focus();
      return false;
    }
    else if (username.trim() <= 1) {
      const result = alert("이름(2글자 이상)을 입력해 주세요!");
      usernameInput.current.focus();
      return false;
    }
    return true;
  }

  const setProfile = (response) => {
    let user = { ...response.data.user };
    user = {
      ...user, 
      refresh: response.data.refresh,
      access: response.data.access
    };
    setUser(user);

    // 인증에 성공한 email을 localStorage에 저장
    localStorage.setItem("user", JSON.stringify({ ...user, id: '', access: '' }));
  };

  return (
    <div className="signup-registry">
      <div className="div-2">
        <div className="middle-section">
          <div className="signup-registry-body">
            <div className="registry-title">
              <div className="registry-title-text">회원가입</div>
            </div>

            <div className="form">
              <div className="registry-container">
                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">이메일아이디*</div>
                  </div>

                  {/* <div className="email-edit">
                    <div className="text-wrapper-6">아이디입력</div>
                  </div> */}
                  <input className="email-edit" text="아이디" name='userid' value={userid} onChange={onChange} ref={useridInput} placeholder="아이디"/>

                  <div className="email-at">
                    <div className="text-wrapper-7">@</div>
                  </div>

                  {/* <div className="email-domain">
                    <div className="text-wrapper-6">도메인</div>
                  </div> */}
                  <input className="email-domain" text="예:naver.com" name='domain' value={domain} onChange={onChange} ref={domainInput} placeholder="도메인"/>

                  <div className="email-btn" style={{cursor:"pointer"}} onClick={e => {onSubmit('auth_code', e)}}>
                    <div className="text-wrapper-8">인증번호전송</div>
                  </div>
                  {/* <div className="email-btn" style={{cursor:"pointer"}} onClick={e => {onSubmit('auth_code', e)}} >이메일 인증</div> */}
                </div>

                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">인증번호*</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-9">인증번호입력</div>
                  </div> */}
                  <input className="div-wrapper-3" text="인증번호" name='auth_code' value={auth_code} onChange={onChange} ref={authCodeInput}/>

                </div>

                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">비밀번호*</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-9">비밀번호입력</div>
                  </div> */}
                  <input className="div-wrapper-3" text="비밀번호" name='password' value={password} onChange={onChange} ref={passwordInput}/>
                </div>

                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">비밀번호확인*</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-9">비밀번호확인 입력</div>
                  </div> */}
                  <input className="div-wrapper-3" text="비밀번호 확인" name='password_confirm' value={password_confirm} onChange={onChange} ref={passwordConfirmInput}/>
                  </div>

                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">스마트폰번호*</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-9">스마트폰번호 입력</div>
                  </div> */}
                  <input className="div-wrapper-3" text="모바일폰" name='mobile' value={mobile} onChange={onChange} ref={mobileInput} />
                  </div>

                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">이름(성명)*</div>
                  </div>

                  {/* <div className="div-wrapper-3">
                    <div className="text-wrapper-9">이름 입력</div>
                  </div> */}
                  <input className="div-wrapper-3" text="이름(실명)" name='username' value={username} onChange={onChange} ref={usernameInput}/>
                  </div>
              </div>
            </div>

            <div className="button-container">
              <div className="link-2" style={{cursor: 'pointer'}} onClick={(e)=>{onSubmit('confirm', e)}}>
                <div className="text-wrapper-10">회원가입</div>
              </div>

              <div className="link-3" style={{cursor: 'pointer'}} onClick={(e)=>{onSubmit('backward', e)}}>
                <div className="text-wrapper-11">이전으로</div>
              </div>
            </div>
          </div>

          <LeftMenu className="left-menu-instance" />
        </div>

        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>
      </div>
    </div>
  );
};
