import axios from "./index";
import https from "./index";

// 수집품 데이터 목록// ?track_total_hits=true
export const getDeterminList = async (params) => {
  const response = await axios.post("argus_op_product_productdetermined-*/_search?track_total_hits=true", params.data);
  return response.data.hits;
};

// 수집품 데이터 상세 정보
export const getDeterminDetail = async (params) => {
  const response = await axios.post("argus_op_product_productdetermined-*/_search", params.data);
  return response.data.hits.hits;
};


class DeterminApi {
  // 수집품 데이터 삭제
  static DeleteDetermin = (params) => {
    const response = axios.post("argus_op_product_productdetermined-*/_delete_by_query", params);
    return response;
  };

  // 수집품 데이터 건수
  static getCollectCount = (params) => {
    const response = axios.post("argus_op_product_productdetermined-*/_count", params);
    return response;
  };

}


export default DeterminApi;

// auth: {
//   username: 'username',
//   password: 'password'
// }
// , headers: { "http.cors.allow-headers": "Access-Control-Allow-Origin,x-csrf-token,authorization,content-type,accept,origin,x-requested-with" } }


// let sss = JSON.stringify({
//   size:10000,
//   query: {
//     match : {
//       mall : {
//         query : 'taobao'
//       }
//     }    
//   }
// })
