import axios from "./index";

export const getAssessList = async (params) => {
  const response = await axios.get("/lawsuits/", { params: params });
  return response.data;
};
export const getAssessDetail = async (formData) => {
  const response = await axios.get(`/lawsuits/${formData.id}/`);
  return response.data;
};

class AssessApi {
  // 판별품 데이터 생성
  static InsertAssess = (formData) => {
    return axios.post(`/productassesses/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productassesses/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default AssessApi;