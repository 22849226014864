import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReportList } from "../components/ReportList";
import { getReportList } from "../modules/reports";
import { useAuth } from "../contexts/auth/auth.context";

export const ReportListContainer = ({platformParam = '', categoryParam = '', infringeParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ platform, setPlatform ] = useState(platformParam);
  const [ category, setCategory ] = useState(categoryParam);
  const [ infringe, setInfringe ] = useState(infringeParam);
  const [ searchType, setSearchType ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.reportReducer.reportlist);
  const dispatch = useDispatch();

  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  const onCategorySubmit = (categoryParam) => {
    setPage(1);
    setCategory(categoryParam);
  }

  const onInfringeSubmit = (infringeParam) => {
    setPage(1);
    setInfringe(infringeParam);
  }

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParam, searchParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearch(searchParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getReportList(platform, category, infringe, searchType, search, page));
  }, [dispatch, platform, category, infringe, searchType, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <ReportList 
    dataParam={data}
    currPage = {pageParam}
    onPlatformSubmit={onPlatformSubmit} 
    onCategorySubmit={onCategorySubmit} 
    onInfringeSubmit={onInfringeSubmit} 
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

