import * as assessApi from "../api/assesses";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_ASSESSLIST = "GET_ASSESSLIST";
const GET_ASSESSLIST_SUCCESS = "GET_ASSESSLIST_SUCCESS";
const GET_ASSESSLIST_ERROR = "GET_ASSESSLIST_ERROR";

// 상품 항목 조회하기
const GET_ASSESSDETAIL = "GET_ASSESSDETAIL";
const GET_ASSESSDETAIL_SUCCESS = "GET_ASSESSDETAIL_SUCCESS";
const GET_ASSESSDETAIL_ERROR = "GET_ASSESSDETAIL_ERROR";

const CLEAR_ASSESSTLIST = "CLEAR_ASSESSTLIST";

export const getAssessList = (platform, progress, todate, searchType, search, pageNumber) => ({
  type: GET_ASSESSLIST,
  payload: { platform: platform, progress: progress, todate: todate, searchType: searchType, search: search, page: pageNumber },
});

export const getAssessDetail = (access, id) => ({
  type: GET_ASSESSDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearAssessList = () => ({ type: CLEAR_ASSESSLIST });

const getAssessListSaga = createPromiseSagaList(
  GET_ASSESSLIST,
  assessApi.getAssessList
);

const getAssessDetailSaga = createPromiseSagaDetail(
  GET_ASSESSDETAIL,
  assessApi.getAssessDetail
);

// 사가들을 합치기
export function* assessSaga() {
  yield takeEvery(GET_ASSESSLIST, getAssessListSaga);
  yield takeEvery(GET_ASSESSDETAIL, getAssessDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  assesslist: reducerUtils.initial(),
  assessdetail: reducerUtils.initial(),
};

export default function assessReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSESSLIST:
    case GET_ASSESSLIST_SUCCESS:
    case GET_ASSESSLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_ASSESSLIST,
        "assesslist",
        true
      );
      return reducerList(state, action);

    case GET_ASSESSDETAIL:
    case GET_ASSESSDETAIL_SUCCESS:
    case GET_ASSESSDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_ASSESSDETAIL,
        "assessdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_ASSESSTLIST:
      return {
        ...state,
        assesslist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
