import axios from "./index";

class SellerApi {
  static getSellerList = async (params) => {
    const response = await axios.get("/sellers/", { params: params });
    return response.data.detail;
  };

  static getSellerDetail = async (params) => {
    const response = await axios.get(`/sellers/${params.id}/`);
    return response.data;
  };

  static postSellerCreate = (formData) => {
    return axios.post(`sellercreate/`, formData);
  };

  static getSellerInfos = async (params) => {
    const response = await axios.get("/seller_infos/", { params: params });
    return response;
  };

}

export default SellerApi;
