import React, {useState, useEffect, useRef } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import moment from "moment"
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
import { DatePickerInput } from "../../components/DatePickerInput";

import { nations, nationOptions } from "../../config/codes";
import { platforms, platformOptions } from "../../config/codes";
import { infringeTypes, infringeTypeOptions } from "../../config/codes";

// 차트 패키지지
import { Bar } from "react-chartjs-2";
import {  Chart as ChartJS,  CategoryScale,  LinearScale,  BarElement,  Title,  Tooltip,  Legend,} from "chart.js";
ChartJS.register( CategoryScale,  LinearScale,  BarElement,  Title,  Tooltip,  Legend );

export const MonitoringList = ({ dataParam, currPage, onPageSubmit, onMonthSubmit, onNationSubmit, onPlatformSubmit, onInfringeTypeSubmit, onProductNameSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const today = new Date()
  const refToDate = useRef();

  // 기간(월)
  const monthOptions = [
  { value: '01', label: '01월' },
  { value: '02', label: '02월' },
  { value: '03', label: '03월' },
  { value: '04', label: '04월' },
  { value: '05', label: '05월' },
  { value: '06', label: '06월' },
  { value: '07', label: '07월' },
  { value: '08', label: '08월' },
  { value: '09', label: '09월' },
  { value: '10', label: '10월' },
  { value: '11', label: '11월' },
  { value: '12', label: '12월' },
  ];  
  const months = {'01': '01월', '02': '02월', '03': '03월', '04': '04월', '05': '05월', '06': '06월', '07': '07월', '08': '08월', '09': '09월', '10': '10월', '11': '11월', '12': '12월'};
  const [month, setMonth] = useState((today.getMonth() > 0 ? today.getMonth() : 12).toString().padStart(2, '0')); // 이전달
  const handleMonth = (e) => {
    let value = e.target.value;
    setMonth(value);

    let year = parseInt(value) <= today.getMonth()+1 ? today.getFullYear() : today.getFullYear()-1;
    let yyyymm = year.toString() + '-' + value.padStart(2, '0')
    onMonthSubmit(yyyymm);
  };

  // 국가
  const [nation, setNation] = useState('');
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    onNationSubmit(value);
  };

  // 플랫폼
  const [platform, setPlatform] = useState('');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 침해유형
  const [infringeType, setInfringeType] = useState('');
  const handleInfringeType = (e) => {
    let value = e.target.value;
    setInfringeType(value);
    onInfringeTypeSubmit(value);
  };

  // 상품명
  const [productName, setProductName] = useState('');
  const handleProductNameChange = (e) => {
    let value = e.target.value;
    setProductName(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter" && productName != "") {
      onProductNameSubmit(productName);
    }
  } 


  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);

  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  const getDaysOfMonth = (month_param) => {
    let day_list = [];
    // 인수로 넘어온 달이 이번달보다 큰 경우 이전 년도로 선택
    let year = month_param <= today.getMonth()+1 ? today.getFullYear() : today.getFullYear()-1;
    let last_day_of_month = new Date(year, month_param, 0).getDate();
    for ( let idx = 0; idx < last_day_of_month; idx++) {
      let yyyymm = year.toString() + '-' + month_param.toString().padStart(2, '0') + '-' + (idx+1).toString().padStart(2, '0');
      day_list.push(yyyymm);
    } 
    return day_list;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js / react-chartjs-2 시작하기",
      },
    },
  };  
  const labels = getDaysOfMonth(month);

  let dataset = [];
  const colorset = [
    "rgba(0, 123, 255, 0.6)",  
    "rgba(255, 0, 0, 0.6)",
    "rgba(0, 0, 255, 0.6)",  
    "rgba(0, 128, 0, 0.6)",
    "rgba(128, 0, 128, 0.6)",
    "rgba(128, 128, 128, 0.6)",
    "rgba(255, 165, 0, 0.6)",  
    "rgba(255, 192, 203, 0.6)",  
    "rgba(255, 255, 0, 0.9)",  
    "rgba(152, 251, 152, 0.8)",  
  ];
  if (datas && datas.length > 0) {
    datas.map((daydata, index, total) => {
      dataset.push(
        {
          label: infringeTypes[daydata.infringe],
          data: daydata.data,
          backgroundColor: colorset[index]
        }
      )
    });
  }

  const data_chart = {
    labels,
    datasets: dataset
  };

  return (
    <div className="monitoring-list">
      <div className="div-3">
        <div className="bulletin-body">
          <div className="body">
            <div className="title-wrapper">
              <div className="title-2">위조품현황</div>
            </div>

            <div className="drop-downs-wrapper">
              <div className="drop-downs">
                <div className="frame">
                  {/* <div className="dropdown-4">
                    <div className="text-wrapper-9">기간</div>
                  </div> */}
                  <select className="dropdown-4" style={{width:"80px", height:"28px", border:"1px solid"}} id="month" name="month" value={month} onChange={handleMonth}>
                    { monthOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                  <div className="dropdown-5">
                    <div className="text-wrapper-10">기간(월)</div>
                  </div>
                </div>

                <div className="frame">
                  {/* <div className="dropdown-6">
                    <div className="text-wrapper-9">저작권</div>
                  </div> */}
                  <select className="dropdown-6" style={{width:"140px", height:"28px", border:"1px solid"}} id="nation" name="nation" value={nation} onChange={handleNation}>
                      { nationOptions.map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                  </select>

                  <div className="dropdown-7">
                    <div className="text-wrapper-10">국가</div>
                  </div>
                </div>

                <div className="frame-2">
                  {/* <div className="dropdown-8">
                    <div className="text-wrapper-9">저작권</div>
                  </div> */}
                  <select className="dropdown-8" style={{width:"140px", height:"28px", border:"1px solid"}} id="platform" name="platform" value={platform} onChange={handlePlatform}>
                      { platformOptions.map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                  </select>

                  <div className="dropdown-9">
                    <div className="text-wrapper-10">플랫폼</div>
                  </div>
                </div>

                <div className="frame-2">
                  {/* <div className="dropdown-10">
                    <div className="text-wrapper-9">저작권</div>
                  </div> */}
                  <select className="dropdown-10" style={{width:"120px", height:"28px", border:"1px solid"}} id="infringeType" name="infringeType" value={infringeType} onChange={handleInfringeType}>
                    { infringeTypeOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                  <div className="dropdown-11">
                    <div className="text-wrapper-10">침해유형</div>
                  </div>
                </div>

                <div className="frame-3">
                  {/* <div className="element-wrapper">
                    <div className="text-wrapper-9">저작권</div>
                  </div> */}
                  <input className="element-wrapper" type="text" name="productName" value={productName} onChange={handleProductNameChange} onKeyDown={(e) => handleKeyDown(e)}>
                    {/* <div className="text-wrapper-6">저작권</div> */}
                  </input>

                  <div className="dropdown-12">
                    <div className="text-wrapper-10">상품</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-chart-2">
              <Bar options={options} data={data_chart ? data_chart : null}/>
            </div>  

            <div className="body-summary-2" />
          </div>
        </div>

        <MainLogo className="main-logo-instance" />
        <LeftMenu className="left-menu-instance" />
        <RightMenu className="right-menu-2" />
      </div>
    </div>
  );
};
